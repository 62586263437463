import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { ProfileSmall } from "grow.client";
import React from "react";
import { history } from "../../functions/history";
import { GrowAvatar } from "../GrowAvatar/GrowAvatar";
import './UserProfileCard.scss';

interface Props {
    userProfile: ProfileSmall
}

export const UserProfileCard: React.FC<Props> = ({ userProfile }) => {

    const onClick = () => history.push(`/${userProfile.username}/devotionals`);

    return (
        <Card className="user-profile-card" onClick={onClick}>
            <CardContent>
                <div className="profile-card">
                    <GrowAvatar profile={userProfile} />
                    <div>
                        <Typography variant="h6">
                            {userProfile?.name}
                        </Typography>
                        <Typography variant="body2">
                            @{userProfile?.username}
                        </Typography>
                    </div>
                </div>
                <Typography className="bio">
                    {userProfile?.bio}
                </Typography>
                <Chip label="Personal" />
            </CardContent>
        </Card>
    );
}