import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DevotionalDay, EDevotionalElementType } from 'grow.client';
import React from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { reorderArray } from '../../../functions/array';
import { addDevotionalDayElement, setDevotionalDayElements } from '../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { useAppDispatch, useSelector } from '../../../store/store';
import { DailyContentCanvasElement } from './DailyContentCanvasElement';
import ConstructionIcon from '@mui/icons-material/Construction';
import './DailyContentCanvas.scss';
import { createNewDailyContentCanvasElement } from '../../../functions/dailyContentElement';

interface Props {
    devotionalDay: DevotionalDay
}

export const DailyContentCanvas: React.FC<Props> = React.memo(({ devotionalDay }) => {
    const dispatch = useAppDispatch();
    const selectedElementId = useSelector(state => state.devotionalBuilderHistory.present.selectedDailyContentElementId);

    const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
        if (!result.destination) return;

        const newDevotionalElements = reorderArray(
            devotionalDay.devotionalElements!,
            result.source.index,
            result.destination.index
        );

        dispatch(setDevotionalDayElements({ id: devotionalDay.id, devotionalElements: newDevotionalElements }));
    }

    const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        const type = e.dataTransfer.getData("type");
        if (type !== "DailyContentToolboxItem") return;

        const newElementIndex = devotionalDay?.devotionalElements?.length ?? 0;
        const toolboxItem = e.dataTransfer.getData("toolboxItem");
        const newDevotionalElement = createNewDailyContentCanvasElement(toolboxItem as EDevotionalElementType, newElementIndex);

        dispatch(addDevotionalDayElement({ index: newElementIndex, devotionalElement: newDevotionalElement }))
    }

    console.log("canvas rerendered " + devotionalDay.id);

    return (
        <Container className="daily-content-canvas paper-panel" >
            <Paper
                className="daily-content-canvas-modal paper-modal"
                elevation={10}
                onDragOver={allowDrop}
                onDrop={onDrop}
                >
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId={`daily-content-canvas-${devotionalDay.id}`}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {devotionalDay.devotionalElements?.map((element, index) => (
                                    <DailyContentCanvasElement
                                        devotionalElement={element}
                                        selected={selectedElementId === element.id}
                                        index={index}
                                        key={element.id}
                                    />
                                ))}
                                {(devotionalDay.devotionalElements?.length ?? 0) === 0 &&
                                    <div className="empty-canvas-message">
                                        <Typography variant="h6">Start by dragging an element from the Toolbox onto this canvas</Typography>
                                        <ConstructionIcon sx={{ fontSize: 48 }} />
                                    </div>}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Paper>
        </Container>
    );
});