import React from "react";
import Avatar from "@mui/material/Avatar";
import './PaperAvatar.scss';

interface Props {
    color: string
}

export const PaperAvatar: React.FC<React.PropsWithChildren<Props>> = ({ color, children }) => {
    return (
        <Avatar className="paper-avatar" sx={{ background: color }}>
            {children}
        </Avatar>
    );
}