import { getRandomNumber } from './random';
import { DevotionalElement, EDevotionalElementType, EListElementType } from 'grow.client';

export const createNewDailyContentCanvasElement = (elementType: EDevotionalElementType, index: number) => {
    const newDevotionalElement: DevotionalElement = {
        id: getRandomNumber(),
        elementType,
        index,
        bibleElement: null,
        gifElement: null,
        headerElement: null,
        imageElement: null,
        listElement: null,
        musicElement: null,
        paragraphElement: null,
        titleElement: null,
        youTubeElement: null
    }

    switch (newDevotionalElement.elementType) {
        case EDevotionalElementType.Bible:
            newDevotionalElement.bibleElement = {
                book: null,
                chapter: 0,
                firstVerse: 0,
                lastVerse: null,
                translation: null,
                translationCode: null,
                passage: null,
                verses: null
            }
            break;
        case EDevotionalElementType.Gif:
            newDevotionalElement.gifElement = {
                height: 0,
                imageId: null,
                url: null,
                width: 0
            }
            break;
        case EDevotionalElementType.Header:
            newDevotionalElement.headerElement = {
                type: null
            }
            break;
        case EDevotionalElementType.Image:
            newDevotionalElement.imageElement = {
                height: 0,
                imageId: null,
                url: null,
                width: 0
            }
            break;
        case EDevotionalElementType.List:
            newDevotionalElement.listElement = {
                text: null,
                type: EListElementType.Bullets
            }
            break;
        case EDevotionalElementType.Paragraph:
            newDevotionalElement.paragraphElement = {
                text: null
            }
            break;
        case EDevotionalElementType.Title:
            newDevotionalElement.titleElement = {
                text: null
            }
            break;
        case EDevotionalElementType.YouTube:
            newDevotionalElement.youTubeElement = {
                youTubeId: null
            }
            break;
    }

    return newDevotionalElement;
}