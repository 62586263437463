import { DevotionalElement, GifSearch, ImageSearch } from 'grow.client';
import produce from 'immer';
import { useAppDispatch } from '../../../../../store/store';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { GifSearchGrid } from '../../../ImageSearchGrid/GifSearchGrid';

interface Props {
    devotionalElement: DevotionalElement
    closeModal: () => void
}

export const GifSettings: React.FC<Props> = ({ devotionalElement, closeModal }) => {
    const dispatch = useAppDispatch();

    const selectImage = (image: ImageSearch | GifSearch) => {
        const updatedDevotionalElement = produce(devotionalElement, draft => {
            draft.gifElement = {
                height: image.height,
                imageId: image.id,
                url: image.imageUrl,
                width: image.width
            };
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));
        closeModal();
    }

    return (
        <div>
            <GifSearchGrid
                columnCount={3}
                scrollableTargetId="image-settings-scrollable-target"
                selectImage={selectImage}
            />
        </div>
    );
}