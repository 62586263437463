import store from './../store/store';
import Client from "grow.client";
import { history } from './history';
import { setBusinessProfiles, setUserProfile } from '../store/slices/userSlice/userSlice';

export const initalise = (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            const client = new Client();
            const user = await client.getCurrentUserProfile();
            store.dispatch(setUserProfile(user));
            const businessMembers = await client.getUserBusinessMembers();
            store.dispatch(setBusinessProfiles(businessMembers));
            resolve();
        } catch {
            const allowedPages = ["/", "/login", "/sign-up", "/forgotten-password"]
            if (!allowedPages.includes(window.location.pathname))
                history.replace("/");
            reject();
        }
    })
}