import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Client, { DevotionalApproval } from "grow.client";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { selectProfileByUsername } from "../../store/slices/userSlice/userSlice";
import store from "../../store/store";
import { formatDate } from '../../functions/date';
import { GrowAvatar } from "../../components/GrowAvatar/GrowAvatar";

interface RouterParams {
    username: string
}

export const ApprovalRequestsPage = () => {
    const { username } = useParams<RouterParams>();
    const [devotionalApprovals, setDevotionalApprovals] = useState<DevotionalApproval[] | null>(null);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
    const devotionalApporvalsCursor = useRef<number | null>(null);

    useEffect(() => {
        getDevotionalApprovalsPage();
    }, [username])

    const getDevotionalApprovalsPage = () => {
        const profile = selectProfileByUsername(username)(store.getState());
        if (!profile) return;

        const client = new Client();
        return client.getBusinessDevotionalApprovals(profile.id, devotionalApporvalsCursor.current).then((newDevotionalApprovals) => {
            const combinedDevotionalApprovals = [...(devotionalApprovals ?? []), ...newDevotionalApprovals.rows!];
            setDevotionalApprovals(combinedDevotionalApprovals);
            devotionalApporvalsCursor.current = newDevotionalApprovals.cursor;

            if (!newDevotionalApprovals.hasNextPage) {
                setDisableInfiniteScroll(true);
            }
            return combinedDevotionalApprovals;
        })
    }

    return (
        <Box component="main">
            <Typography variant="h4">
                Devotional Approval Requests
            </Typography>
            {devotionalApprovals &&
                <InfiniteScroll
                    dataLength={devotionalApprovals?.length}
                    hasMore={!disableInfiniteScroll}
                    next={getDevotionalApprovalsPage}
                    loader={<div>Loading...</div>}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Creator</TableCell>
                                    <TableCell>Date Created</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {devotionalApprovals.map((devotionalApproval) => (
                                    <TableRow key={devotionalApproval.id}>
                                        <TableCell width="100px">
                                            <img src={devotionalApproval.devotional!.image!} alt={`${devotionalApproval.devotional!.name} cover`} style={{ width: 100 }} />
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>{devotionalApproval.devotional!.name}</TableCell>
                                        <TableCell width="30%">{devotionalApproval.devotional!.description}</TableCell>
                                        <TableCell>
                                            <ListItem sx={{ padding: 0 }}>
                                                <ListItemAvatar>
                                                    <GrowAvatar profile={devotionalApproval.devotional!.userProfile!} />
                                                </ListItemAvatar>
                                                <ListItemText primary={devotionalApproval.devotional!.userProfile!.name} secondary={`@${devotionalApproval.devotional!.userProfile!.username}`} />
                                            </ListItem>
                                        </TableCell>
                                        <TableCell>{formatDate(devotionalApproval.created)}</TableCell>
                                        <TableCell>
                                            <Chip label={devotionalApproval.status} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>}
        </Box>
    );
}