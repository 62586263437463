import * as React from 'react';
import YouTube from 'react-youtube';
import './YouTubeFrame.scss';

interface Props {
    videoId: string
}

export const YouTubeFrame: React.FC<Props> = React.memo(({ videoId }) => {
    return (
        <div className="youtube-frame">
            <div className="youtube-container">
                <div className="cover" />
                <YouTube
                    videoId={videoId}
                    key={videoId}
                    opts={{
                        playerVars: {
                            modestbranding: 1,
                            playsinline: 1
                        }
                    }} />
            </div>
        </div>
    )
});