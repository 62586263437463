import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ProfileSmall } from 'grow.client';
import { GrowAvatar } from '../GrowAvatar/GrowAvatar';
import { useSelector } from '../../store/store';
import { selectDevotionalCreatorProfiles } from '../../store/slices/userSlice/userSlice';

interface Props {
    value: ProfileSmall | null
    setValue: (value: ProfileSmall | null) => void
}

export const UserProfileSearchInput: React.FC<Props> = ({ value, setValue }) => {
    const options = useSelector(selectDevotionalCreatorProfiles); 

    return (
        <div style={{ display: "flex", alignItems: "center", marginTop: 16, marginBottom: 8 }}>
            <GrowAvatar profile={value} />
            <Autocomplete
                sx={{ ml: 1, flexGrow: 1 }}
                getOptionLabel={(option) => option.name!}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                onChange={(event: any, newValue: ProfileSmall | null) => {
                    setValue(newValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Search for a profile" fullWidth />
                )}
                renderOption={(props, profile) => {
                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <GrowAvatar profile={profile} />
                                </Grid>
                                <Grid item xs sx={{ ml: 1 }}>
                                    <Typography variant="body1">
                                        {profile?.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        @{profile.username}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
        </div>
    );
}
