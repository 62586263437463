import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Client, { EProfileSearchType, ProfileSmall } from 'grow.client';
import { GrowAvatar } from '../GrowAvatar/GrowAvatar';

interface Props {
    value: ProfileSmall | null
    setValue: (value: ProfileSmall | null) => void
}

export const ProfileSearchInput: React.FC<Props> = ({ value, setValue }) => {
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly ProfileSmall[]>([]);

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const client = new Client();
        client.searchProfiles(inputValue, 1, null, EProfileSearchType.User).then((results) => {
            if (active) {
                let newOptions: readonly ProfileSmall[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue]);

    return (
        <div style={{ display: "flex", alignItems: "center", marginTop: 24, marginBottom: 16 }}>
            <GrowAvatar profile={value} />
            <Autocomplete
                sx={{ ml: 1, flexGrow: 1 }}
                getOptionLabel={(option) => option.name!}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                onChange={(event: any, newValue: ProfileSmall | null) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Search for a profile" fullWidth />
                )}
                renderOption={(props, profile) => {
                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <GrowAvatar profile={profile} />
                                </Grid>
                                <Grid item xs sx={{ ml: 1 }}>
                                    <Typography variant="body1">
                                        {profile?.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        @{profile.username}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
        </div>
    );
}
