import React, { useEffect } from "react";
import { Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import "./TwoFactorAuthenticationModal.scss";

interface Props {
    errorMessage: string
    isOpen: boolean
    isSubmitting: boolean
    verifyFunc: (e: React.FormEvent<HTMLFormElement>) => void
    setShowModal: (value: boolean) => void
    setTwoFactorAuthenticationCode: (value: string) => void
    twoFactorAuthenticationCode: string
}

export const TwoFactorAuthenticationModal: React.FC<Props> = ({ errorMessage, isOpen, isSubmitting, verifyFunc, setShowModal, setTwoFactorAuthenticationCode, twoFactorAuthenticationCode }) => {
    useEffect(() => {
        if (isOpen) return;
        setTwoFactorAuthenticationCode("");
    }, [isOpen])

    const requiredFieldsComplete = !(!!twoFactorAuthenticationCode);

    return (
        <div
            className="two-factor-authentication-modal"
            hidden={!isOpen}
        >
            <Container component="main" maxWidth="sm">
                <Typography variant="h3">
                    Two Factor Authentication
                </Typography>
                <Typography color="text.secondary">Enter the 6-digit two-factor authentication code generated on your device.</Typography>
                <form onSubmit={verifyFunc}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="two-factor-authentication-code"
                        label="2FA Code"
                        id="two-factor-authentication-code"
                        inputProps={{
                            maxLength: 6
                        }}
                        sx={{
                            marginTop: 2
                        }}
                        value={twoFactorAuthenticationCode}
                        onChange={e => setTwoFactorAuthenticationCode(e.target.value)}
                    />
                    <Button
                        type="submit"
                        disabled={requiredFieldsComplete || isSubmitting}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation={true}
                        className="submit lg-button"
                    >
                        {!isSubmitting ? "Verify" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="info"
                        disableElevation={true}
                        className="cancel lg-button"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </Button>
                    {errorMessage !== "" &&
                        <Typography color="error">
                            <p>{errorMessage}</p>
                        </Typography>}
                </form>
            </Container>
        </div>
    )
}