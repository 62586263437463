import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import './SettingsModal.scss';

interface Props {
    handleClose: () => void
    fullHeight: boolean
    show: boolean
    size: "sm" | "md"
    title: string | undefined
}

export const SettingsModal: React.FC<Props> = ({ children, fullHeight, handleClose, show, size, title }) => {

    return (
        <Dialog
            open={show}
            fullWidth
            maxWidth={size}
            onClose={handleClose}
            onClick={e => e.stopPropagation()}
            onMouseMove={e => e.stopPropagation()}
            className={ fullHeight ? "full-height" : "" }
        >
            <DialogTitle>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <DialogContent id="image-settings-scrollable-target">
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}