import { useAppDispatch, useSelector } from '../../../store/store';
import { addDevotionalDay, setDevotionalDays } from '../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { DailyContentDaySelectorItem } from './DailyContentDaySelectorItem';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { reorderArray } from '../../../functions/array';
import './DailyContentDaySelector.scss';
import { DevotionalDay, DevotionalDayResource } from 'grow.client';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';
import Grid from '@mui/material/Grid';

export const DailyContentDaySelector = () => {
    const devotionalDays = useSelector(state => state.devotionalBuilderHistory.present.devotional?.devotionalDays);
    const dispatch = useAppDispatch();

    const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
        if (!result.destination || !devotionalDays) return;

        const newDevotionalDays = reorderArray<(DevotionalDay & DevotionalDayResource)>(
            devotionalDays,
            result.source.index,
            result.destination.index
        );
        dispatch(setDevotionalDays(newDevotionalDays))
    }

    const addNewDay = () => {
        dispatch(addDevotionalDay());
    }

    return (
        <Container className="paper-panel daily-content-day-selector" sx={{ marginTop: 0 }}>
            <Paper id="daily-content-day-selector-modal" className="paper-modal" elevation={10}>
                <Grid container>
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Days</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={addNewDay}
                            variant="contained"
                            startIcon={<Add />}
                            disableElevation={true}
                            className="add-new-day-button">
                            New Day
                        </Button>
                    </Grid>
                </Grid>
                {devotionalDays &&
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="day-selector">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {devotionalDays.map((item, index) => (
                                        <DailyContentDaySelectorItem devotionalDay={item} index={index} />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>}
            </Paper>
        </Container>
    )
}