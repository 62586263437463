import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import InputAdornment from "@mui/material/InputAdornment"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from "react"
import { Copyright } from "../../components/Copyright/Copyright"
import Client, { CreateUserProfileResource, LoginResource } from "grow.client"
import { history } from '../../functions/history';
import { initalise } from '../../functions/initalise';
import { getShortDateFormat } from '../../functions/date';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import './SignUpPage.scss';

export const SignUpPage = () => {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);

    const [usernameCheckLoading, setUsernameCheckLoading] = useState(false);
    const [isUsernameValid, setIsUsernameValid] = useState<boolean | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Check username is valid on update
    useEffect(() => {
        const checkIsUsernameValid = () => {
            const client = new Client();
            if (username === "") {
                setIsUsernameValid(null);
                return;
            };
            setUsernameCheckLoading(true);
            client.isUsernameValid(username).then((isUsernameValid) => {
                setUsernameCheckLoading(false);
                setIsUsernameValid(isUsernameValid.isValid);
            })
        }

        const timeoutId = setTimeout(() => checkIsUsernameValid(), 500);
        return () => clearTimeout(timeoutId);
    }, [username]);

    const createProfile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        const client = new Client();

        const createUserProfileResource: CreateUserProfileResource = {
            name: name,
            username: username,
            emailAddress: email,
            password: password,
            dateOfBirth: dateOfBirth ?? ""
        };

        client.createUserProfile(createUserProfileResource).then((profile) => {
            const loginResource: LoginResource = {
                emailAddress: email,
                password,
                twoFactorAuthenticationCode: null
            };
            client.login(loginResource).then((authToken) => {
                initalise().finally(() => {
                    setIsSubmitting(false);
                    history.replace("/home");
                });
            });
        }).catch((res) => {
            const errorMessage = JSON.parse(res.response).Message;
            setErrorMessage(errorMessage);
            setIsSubmitting(false);
        });
    }

    let usernameIcon = <div />;
    if (usernameCheckLoading) usernameIcon = <CircularProgress size={24} />;
    else {
        if (isUsernameValid === null) usernameIcon = <div />;
        else if (isUsernameValid) usernameIcon = <CheckCircleOutlineIcon color="success" />;
        else if (!isUsernameValid) usernameIcon = <HighlightOffIcon color="error" />;
    }

    const requiredFieldsComplete = !!name && !!username && !!email && !!password && !!dateOfBirth && isUsernameValid;
    const dateFormat = getShortDateFormat();

    return (
        <Container id="sign-up-page" component="main" maxWidth="sm">
            <div className="paper">
                <Avatar className="avatar">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h3">
                    Sign up
                </Typography>
                <form className="form" onSubmit={createProfile}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        onChange={e => setName(e.target.value)} />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        onChange={e => setUsername(e.target.value)}
                        InputProps={{
                            spellCheck: false,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {usernameIcon}
                                </InputAdornment>
                            )
                        }} />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={e => setEmail(e.target.value)}
                        inputProps={{
                            maxLength: 100
                        }} />
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <DesktopDatePicker
                            disableFuture
                            label="Date of Birth"
                            value={dateOfBirth}
                            inputFormat={dateFormat}
                            onChange={(date: Date | null) => setDateOfBirth(date ? date.toISOString() : null)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required
                                    fullWidth
                                    margin="dense"
                                />}
                        />
                    </LocalizationProvider>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => setPassword(e.target.value)}
                        inputProps={{
                            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$",
                            title: "Passwords must contain an uppercase letter, lowercase letter and a number."
                        }} />
                    <Typography variant="caption">
                        Passwords must contain an uppercase letter, lowercase letter and a number.
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation={true}
                        className="submit lg-button"
                        disabled={!requiredFieldsComplete}>
                        {!isSubmitting ? "Sign up" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                    </Button>
                    <Typography variant="body1" color="error" align="center">
                        {errorMessage}
                    </Typography>
                    <Typography className="lbl-terms" align="center">
                        <p>By signing up, you agree to the <a href="https://growfaith.co/terms-conditions" target="_blank" rel="noreferrer">Terms of Service</a> and <a href="https://growfaith.co/privacy" target="_blank" rel="noreferrer">Privacy Policy.</a></p>
                    </Typography>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}