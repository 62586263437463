import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import store, { useAppDispatch } from '../../../store/store';
import { PhotoSearchGrid } from '../ImageSearchGrid/PhotoSearchGrid';
import Client, { ApiException, EMediaType, GifSearch, ImageSearch } from 'grow.client';
import produce from 'immer';
import { setCoverImage } from '../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { useState } from 'react';
import './CoverImagePhotoSearch.scss';
import { selectFiles } from '../../../functions/selectFiles';
import { compressImage } from '../../../functions/image';

interface Props {
    columnCount: 2 | 3
}

export const CoverImagePhotoSearch: React.FC<Props> = ({ columnCount }) => {
    const dispatch = useAppDispatch();
    const [backgroundImageTab, setBackgroundImageTab] = useState("library");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const selectPhoto = (photo: ImageSearch | GifSearch) => {
        const coverImage = store.getState().devotionalBuilderHistory.present.devotional?.coverImage;
        if (!coverImage) return;

        const updatedCoverImage = produce(coverImage, draft => {
            draft.unsplashId = photo.id;
            draft.backgroundImageUrl = photo.imageUrl += "&w=1920&q=80";
            draft.y = 0;
        });

        dispatch(setCoverImage(updatedCoverImage));
    }

    const uploadCoverImageBackground = () => {
        selectFiles({ accept: 'image/*', multiple: false }).then(async (files) => {
            if (!files) return;

            const compressedImage = await compressImage(files[0], 1920, 5400);
            try {
                const client = new Client();
                const media = await client.uploadMedia(EMediaType.DevotionalCoverImageBackground, {
                    data: compressedImage,
                    fileName: "upload.jpg"
                });

                const coverImage = store.getState().devotionalBuilderHistory.present.devotional?.coverImage;
                if (!coverImage) return;

                const updatedCoverImage = produce(coverImage, draft => {
                    draft.unsplashId = null;
                    draft.backgroundImageUrl = media.url;
                    draft.y = 0;
                });

                setErrorMessage(null);
                dispatch(setCoverImage(updatedCoverImage));
            } catch (error) {
                const errorJson = JSON.parse((error as ApiException).response);
                setErrorMessage(errorJson.Message);
            }
        });
    }

    return (
        <Container className="paper-panel cover-image-photo-search" sx={{ marginTop: 0 }}>
            <Paper id="image-search-grid-scrollable-target" className="paper-modal" elevation={10}>
                <Tabs
                    value={backgroundImageTab}
                    onChange={(e, value) => setBackgroundImageTab(value)}
                >
                    <Tab label="Library" value="library" />
                    <Tab label="Upload" value="upload" />
                </Tabs>
                <div hidden={backgroundImageTab !== "library"}>
                    <PhotoSearchGrid
                        columnCount={2}
                        scrollableTargetId="image-search-grid-scrollable-target"
                        selectImage={selectPhoto} />
                </div>
                <div hidden={backgroundImageTab !== "upload"}>
                    <div className="upload-cover-image-background-panel">
                        <div className="button-container">
                            <IconButton
                                className="upload-cover-image-background-button"
                                onClick={uploadCoverImageBackground}>
                                <CloudUploadOutlinedIcon sx={{ fontSize: 64 }} />
                            </IconButton>
                        </div>
                        <Typography variant="caption">
                            Upload your own cover image design. It is recommended that images should be 1920px by 1080px to fit the canvas correctly.
                        </Typography>
                        {errorMessage &&
                            <Typography variant="body2" color="error" align="center" fontWeight="700" sx={{ marginTop: 1 }}>
                                {errorMessage}
                            </Typography>}
                    </div>
                </div>
            </Paper>
        </Container>
    )
}