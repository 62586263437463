import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Switch } from 'react-router-dom';
import { history } from './functions/history';

import { Header } from './components/Header/Header';
import { Menu } from './components/Menu/Menu';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Snackbar from '@mui/material/Snackbar';

// Pages
import { ApprovalRequestsPage } from './pages/ApprovalRequestsPage/ApprovalRequestsPage';
import { DevotionalBuilderPage } from './pages/DevotionalBuilderPage/DevotionalBuilderPage';
import { DevotionalsPage } from './pages/DevotionalsPage/DevotionalsPage';
import { ForgottenPasswordPage } from './pages/ForgottenPasswordPage/ForgottenPasswordPage';
import { HomePage } from './pages/HomePage/HomePage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { MembersPage } from './pages/MembersPage/MembersPage';
import { OverviewPage } from './pages/OverviewPage/OverviewPage';
import { PostsPage } from './pages/PostsPage/PostsPage';
import { SignUpPage } from './pages/SignUpPage/SignUpPage';
import { StartUpPage } from './pages/StartUpPage/StartUpPage';

// Theming
import "./theme/theme.scss";
import './App.scss';

import React, { useEffect, useState } from 'react';
import Client from 'grow.client';
import { useDispatch } from 'react-redux';
import { logOutUser } from './store/slices/userSlice/userSlice';
import { initalise } from './functions/initalise';
import { useSelector } from './store/store';
import { setShowAlert, setShowSnackBar, setSnackbarOptions } from './store/slices/uiSlice/uiSlice';

const theme = createTheme({
    palette: {
        primary: {
            main: "#3cb371",
            contrastText: "#fff"
        },
        secondary: {
            main: "#fff",
            contrastText: "#fff"
        },
        info: {
            main: "#f4f5f8",
            contrastText: "#000000"
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Helvetica Neue"',
            'Roboto',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        allVariants: {
            color: "#161616"
        },
        button: {
            textTransform: "unset"
        },
        h1: {
            fontWeight: 900
        },
        h2: {
            fontWeight: 900
        },
        h3: {
            fontWeight: 900
        },
        h4: {
            fontWeight: 900,
            marginBottom: 16
        },
        h5: {
            fontWeight: 400
        },
        h6: {
            fontWeight: 400
        }
    }
});


export const App = () => {
    const [hasInitalised, setHasInitalised] = useState(false);
    const [bodyStyle, setBodyStyle] = useState<React.CSSProperties>({});
    const showAlert = useSelector(state => state.ui.showAlert);
    const alertOptions = useSelector(state => state.ui.alertOptions);
    const showSnackbar = useSelector(state => state.ui.showSnackbar);
    const snackbarOptions = useSelector(state => state.ui.snackBarOptions);

    const user = useSelector(state => state.user.userProfile);
    const dispatch = useDispatch();

    useEffect(() => {
        Client.clientName = "Grow Studio";
        Client.failedToRefresh = () => {
            delete localStorage["accessTokenExpiry"]
            dispatch(logOutUser());
            history.replace("/login");

            dispatch(setSnackbarOptions({ message: "You've been logged out.", color: "success" }));
            dispatch(setShowSnackBar(true));
        }

        initalise().finally(() => {
            setHasInitalised(true);
        });

        const unregister = history.listen((location, action) => {
            changeBodyStyle();
        });

        changeBodyStyle();

        return () => {
            unregister();
        }
    }, [dispatch]);

    const changeBodyStyle = () => {
        const bodyStyle = history.location.pathname.indexOf("/devotional/") === 0 ? { overflow: "hidden" } : {};
        setBodyStyle(bodyStyle);
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="app">
                <CssBaseline />
                <Header />
                {hasInitalised &&
                    <div className="body" style={bodyStyle}>
                        <Route path={[
                            "/:username/approval-requests",
                            "/:username/devotionals",
                            "/:username/members",
                            "/:username/overview",
                            "/:username/posts"
                        ]} component={Menu} />
                        <Switch>
                            <Route exact path={["/", "/home"]} component={user ? HomePage : StartUpPage} />
                            <Route exact path="/devotional/:devotionalId" component={DevotionalBuilderPage} />
                            <Route exact path="/forgotten-password" component={ForgottenPasswordPage} />
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/sign-up" component={SignUpPage} />
                            <Route path="/:username/approval-requests" component={ApprovalRequestsPage} />
                            <Route path="/:username/devotionals" component={DevotionalsPage} />
                            <Route path="/:username/members" component={MembersPage} />
                            <Route path="/:username/overview" component={OverviewPage} />
                            <Route path="/:username/posts" component={PostsPage} />
                        </Switch>
                    </div>}
            </div>
            <Snackbar
                open={showSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={3000}
                onClose={() => dispatch(setShowSnackBar(false))}>
                <Alert
                    onClose={() => dispatch(setShowSnackBar(false))}
                    severity={snackbarOptions?.color}
                    sx={{ width: '100%' }}
                    variant={snackbarOptions?.color ? "filled" : "standard"}
                >
                    {snackbarOptions?.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={showAlert}
                onClose={() => dispatch(setShowAlert(false))}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {alertOptions?.header}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ whiteSpace: "pre-line" }}>
                        {alertOptions?.body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dispatch(setShowAlert(false))}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}
