import * as React from 'react';
import { EListElementType } from 'grow.client';
import './ListElement.scss';

interface Props {
    text: string
    type: EListElementType
}

export const ListElement: React.FC<Props> = ({ text, type }) => {
    const itemsString = text.replace(/\n--/g, "{newline}").replace(/--/g, "{newline}");
    const items = itemsString.split(/\n/);
    const itemsTags = items.map((item, index) => {
        item = item.replace(/{newline}/g, "\n");
        return (
            <li key={index}>
                <span>{item}</span>
            </li>);
    });

    const classes = "list-element";
    const typeTag = type === EListElementType.Numbered ? <ol className={classes}>{itemsTags}</ol> : <ul className={classes}>{itemsTags}</ul>;

    return (
        typeTag
    )
}