import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import BrushIcon from '@mui/icons-material/Brush';
import { CoverImageBackgroundSettings } from "../../components/DevotionalBuilder/CoverImageBackgroundSettings/CoverImageBackgroundSettings";
import { CoverImageCanvas } from "../../components/DevotionalBuilder/CoverImageCanvas/CoverImageCanvas";
import { CoverImagePhotoSearch } from "../../components/DevotionalBuilder/CoverImagePhotoSearch/CoverImagePhotoSearch";
import { CoverImageToolBox } from "../../components/DevotionalBuilder/CoverImageToolBox/CoverImageToolBox";
import { PaperAvatar } from "../../components/PaperAvatar/PaperAvatar";
import { useAppDispatch, useSelector } from "../../store/store";
import { copySelectedCoverImageLayer, deleteSelectedCoverImageLayer, getSelectedCoverImageLayer } from "../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice";
import { CoverImageTextSettings } from "../../components/DevotionalBuilder/CoverImageLayerSettings/CoverImageTextSettings";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { DevotionalCoverImageTextLayer, EDevotionalCoverImageLayerType } from "grow.client";
import Konva from "konva";

interface Props {
    coverImageStageRef: React.RefObject<Konva.Stage>
}

export const CoverImageDesignerTab: React.FC<Props> = ({ coverImageStageRef }) => {
    const selectedLayer = useSelector(getSelectedCoverImageLayer);
    const dispatch = useAppDispatch();
    
    const copyLayer = () => {
        dispatch(copySelectedCoverImageLayer());
    }

    const deleteLayer = () => {
        dispatch(deleteSelectedCoverImageLayer());
    }

    return (
        <Grid container>
            <Grid item sm={3}>
                <CoverImagePhotoSearch columnCount={2} />
            </Grid>
            <Grid item sm={6}>
                <CoverImageToolBox />
                <CoverImageCanvas coverImageStageRef={coverImageStageRef} />
            </Grid>
            <Grid item sm={3}>
                <Container className="paper-panel cover-image-settings">
                    <PaperAvatar color="seagreen">
                        <BrushIcon />
                    </PaperAvatar>
                    <Paper id="cover-image-settings-modal" className="paper-modal" elevation={10}>
                        {!selectedLayer &&
                            <CoverImageBackgroundSettings />}
                        {selectedLayer &&
                            <ButtonGroup variant="contained" color="primary" fullWidth>
                                <Button onClick={copyLayer}>
                                    <ContentCopyIcon /> Copy
                                </Button>
                                <Button onClick={deleteLayer}>
                                    <DeleteIcon /> Delete
                                </Button>
                            </ButtonGroup>}
                        {selectedLayer && selectedLayer.type === EDevotionalCoverImageLayerType.Text &&
                            <CoverImageTextSettings layer={(selectedLayer as DevotionalCoverImageTextLayer)} />}
                    </Paper>
                </Container>
            </Grid>
        </Grid>
    );
}