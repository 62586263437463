import Typography from "@mui/material/Typography";
import Client, { ApiException } from "grow.client";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router";
import { setPendingMembers } from "../../store/slices/memberSlice/memberSlice";
import { selectProfileByUsername } from "../../store/slices/userSlice/userSlice";
import store, { useAppDispatch, useSelector } from '../../store/store';
import { MembersTable } from "./MembersTable";

interface RouterParams {
    username: string
}

type BusinessMemberPermission = "admin" | "devotionalCreator" | "devotionalCreatorWithReview" | "postCreator" | "proofReader"

export const PendingMembersTab: React.FC = () => {
    const { username } = useParams<RouterParams>();
    const [disableMemberApprovalsInfiniteScroll, setDisableMemberApprovalsInfiniteScroll] = useState(false);
    const memberApprovals = useSelector(state => state.member.pendingMembers);
    const memberApprovalsCursor = useRef<number | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setPendingMembers(null));
        getMemberApprovalsPage();
    }, [username])

    const getMemberApprovalsPage = () => {
        const profile = selectProfileByUsername(username)(store.getState());
        if (!profile) return;

        const client = new Client();
        return client.getBusinessBusinessMemberApprovals(profile.id, memberApprovalsCursor.current).then((newMembers) => {
            const combinedMemberApprovals = [...(store.getState().member.pendingMembers ?? []), ...newMembers.rows!];
            dispatch(setPendingMembers(combinedMemberApprovals));
            memberApprovalsCursor.current = newMembers.cursor;

            if (!newMembers.hasNextPage) {
                setDisableMemberApprovalsInfiniteScroll(true);
            }
            return combinedMemberApprovals;
        })
    }

    const updatePermissionState = (businessMemberApprovalId: number, permission: BusinessMemberPermission, value: boolean) => {
        const newArr = [...memberApprovals!];
        const item = newArr.find(i => i.id === businessMemberApprovalId);
        if (!item) return;
        item[permission] = value;
        dispatch(setPendingMembers(newArr));
        return item;
    }

    const changePermission = (businessMemberApprovalId: number, permission: BusinessMemberPermission, value: boolean) => {
        const item = updatePermissionState(businessMemberApprovalId, permission, value);
        if (!item) return;

        const client = new Client();
        client.updateBusinessMemberApproval(businessMemberApprovalId, item).catch((error: ApiException) => {
            const errorJson = JSON.parse(error.response);
            alert(errorJson.Message);
            updatePermissionState(businessMemberApprovalId, permission, !value);
        });
    }

    return (
        <React.Fragment>
            <Typography variant="h4">
                Pending Members
            </Typography>
            {memberApprovals &&
                <InfiniteScroll
                    dataLength={memberApprovals?.length}
                    hasMore={!disableMemberApprovalsInfiniteScroll}
                    next={getMemberApprovalsPage}
                    loader={<div>Loading...</div>}>
                    <MembersTable changePermission={changePermission} members={memberApprovals} />
                </InfiniteScroll>}
        </React.Fragment>
    );
}