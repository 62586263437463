import * as React from 'react';
import './ParagraphElement.scss';

interface Props {
    text: string
}

export const ParagraphElement: React.FC<Props> = React.memo(({ text }) => {
    return (
        <p className="paragraph-element">
            {text}
        </p>
    )
});