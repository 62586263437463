import { RootState } from '../../store';
import { DevotionalBuilderDevotional } from '../../../models/DevotionalBuilderDevotional';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag, DevotionalDay, DevotionalDayResource, DevotionalElement, DevotionalCoverImageLayer, DevotionalCoverImage } from 'grow.client';
import { getRandomNumber } from '../../../functions/random';

interface CoverImageLayerPosition {
    index: number,
    x: number
    y: number
}

export interface sliceState {
    coverImageSelectedLayer: number | null,
    devotional: DevotionalBuilderDevotional | null
    selectedDevotionalDayId: number | null
    selectedDailyContentElementId: number | null
    step: number
}

const initialState: sliceState = {
    coverImageSelectedLayer: null,
    devotional: null,
    selectedDevotionalDayId: null,
    selectedDailyContentElementId: null,
    step: 0
};

const devotionalBuilderHistorySlice = createSlice({
    name: 'devotionalBuilderHistory',
    initialState,
    reducers: {
        addCoverImageLayer: (state, action: PayloadAction<DevotionalCoverImageLayer>) => {
            if (!state.devotional?.coverImage?.layers) return;
            state.devotional.coverImage.layers.push(action.payload);
        },
        addDevotionalDay: (state) => {
            state.devotional?.devotionalDays?.push({
                id: getRandomNumber(),
                index: state.devotional.devotionalDays.length,
                devotionalElements: []
            })
        },
        addDevotionalDayElement: (state, action: PayloadAction<{ index: number, devotionalElement: DevotionalElement }>) => {
            const day = state.devotional?.devotionalDays?.find(d => d.id === state.selectedDevotionalDayId);
            if (!day || !day.devotionalElements) return;
            day.devotionalElements.splice(action.payload.index, 0, action.payload.devotionalElement);
            day.devotionalElements = day.devotionalElements.map((element, index) => { return { ...element, index } }); // set indexes
        },
        copyDevotionalDay: (state, action: PayloadAction<DevotionalDay>) => {
            state.devotional?.devotionalDays?.push({
                id: getRandomNumber(),
                index: state.devotional.devotionalDays.length,
                devotionalElements: action.payload.devotionalElements ? action.payload.devotionalElements.map(e => { return { ...e, id: getRandomNumber() } }) : []
            })
        },
        copySelectedCoverImageLayer: (state) => {
            if (!state.devotional?.coverImage?.layers) return;

            const selectedLayer = state.devotional.coverImage.layers[state.coverImageSelectedLayer ?? 0];
            const newSelectedLayer = { ...selectedLayer };
            newSelectedLayer.y += 100;
            if (newSelectedLayer.y > 1000) newSelectedLayer.y = 1000; // prevent exceeding bounds
            state.devotional.coverImage.layers.push(newSelectedLayer);
            state.coverImageSelectedLayer = state.devotional.coverImage.layers.length - 1;
        },
        deleteDevotionalDay: (state, action: PayloadAction<{ index: number }>) => {
            if (!state.devotional?.devotionalDays) return;
            state.devotional.devotionalDays.splice(action.payload.index, 1);
            state.devotional.devotionalDays = state.devotional.devotionalDays.map((day, index) => { return { ...day, index } }); // set indexes
        },
        deleteDevotionalElement: (state, action: PayloadAction<number>) => {
            if (!state.devotional) return;

            for (let i = 0; i < state.devotional.devotionalDays!.length; i++) {
                for (let j = 0; j < state.devotional.devotionalDays![i].devotionalElements!.length; j++) {
                    if (state.devotional.devotionalDays![i].devotionalElements![j].id === action.payload) {
                        state.devotional.devotionalDays![i].devotionalElements!.splice(j, 1);
                        state.devotional.devotionalDays![i].devotionalElements = state.devotional.devotionalDays![i].devotionalElements!.map((element, index) => { return { ...element, index } }); // set indexes
                        return
                    }
                }
            }
        },
        deleteSelectedCoverImageLayer: (state) => {
            if (!state.devotional?.coverImage?.layers) return;
            const selectedLayer = state.coverImageSelectedLayer;
            state.devotional.coverImage.layers = state.devotional.coverImage.layers.filter((l, index) => index !== selectedLayer);
            state.coverImageSelectedLayer = null;
        },
        resetHistoryState: (state) => {
            state.coverImageSelectedLayer = null;
            state.devotional = null;
            state.selectedDailyContentElementId = null;
            state.selectedDevotionalDayId = null;
            state.step = 0;
        },
        setCoverImage: (state, action: PayloadAction<DevotionalCoverImage>) => {
            if (!state.devotional) return;
            state.devotional.coverImage = action.payload;
        },
        setCoverImageBackgroundPosition: (state, action: PayloadAction<number>) => {
            if (!state.devotional?.coverImage) return;
            state.devotional.coverImage.y = Math.round(action.payload);
        },
        setCoverImageLayer: (state, action: PayloadAction<{ index: number, layer: DevotionalCoverImageLayer }>) => {
            if (!state.devotional?.coverImage?.layers) return;
            state.devotional.coverImage.layers[action.payload.index] = action.payload.layer;
        },
        setCoverImageLayerPosition: (state, action: PayloadAction<CoverImageLayerPosition>) => {
            if (!state.devotional?.coverImage?.layers) return;
            state.devotional.coverImage.layers[action.payload.index].x = action.payload.x;
            state.devotional.coverImage.layers[action.payload.index].y = action.payload.y;
        },
        setCoverImageSelectedLayer: (state, action: PayloadAction<number | null>) => {
            state.coverImageSelectedLayer = action.payload;
        },
        setDevotional: (state, action: PayloadAction<DevotionalBuilderDevotional>) => {
            state.devotional = action.payload;
        },
        setDevotionalDayElements: (state, action: PayloadAction<{ id: number, devotionalElements: DevotionalElement[] }>) => {
            const day = state.devotional?.devotionalDays?.find(d => d.id === action.payload.id);
            if (!day) return;
            day.devotionalElements = action.payload.devotionalElements;
            day.devotionalElements = day.devotionalElements.map((element, index) => { return { ...element, index } }); // set indexes
        },
        setDevotionalDays: (state, action: PayloadAction<DevotionalDay[] & DevotionalDayResource[]>) => {
            if (!state.devotional) return;
            state.devotional.devotionalDays = action.payload.map((day, index) => { return { ...day, index } }); // set indexes
        },
        setDevotionalDescription: (state, action: PayloadAction<string>) => {
            if (!state.devotional) return;
            state.devotional.description = action.payload;
        },
        setDevotionalElement: (state, action: PayloadAction<DevotionalElement>) => {
            if (!state.devotional) return;

            for (let i = 0; i < state.devotional.devotionalDays!.length; i++) {
                for (let j = 0; j < state.devotional.devotionalDays![i].devotionalElements!.length; j++) {
                    if (state.devotional.devotionalDays![i].devotionalElements![j].id === action.payload.id) {
                        state.devotional.devotionalDays![i].devotionalElements![j] = action.payload;
                        return
                    }
                }
            }
        },
        setDevotionalTags: (state, action: PayloadAction<Tag[]>) => {
            if (!state.devotional) return;
            state.devotional.tags = action.payload;
        },
        setDevotionalTitle: (state, action: PayloadAction<string>) => {
            if (!state.devotional) return;
            state.devotional.name = action.payload;
        },
        setPublishingProfile: (state, action: PayloadAction<number | null>) => {
            if (!state.devotional) return;
            state.devotional.businessProfileId = action.payload;
        },
        setSelectedDailyContentElementId: (state, action: PayloadAction<number | null>) => {
            state.selectedDailyContentElementId = action.payload;
        },
        setSelectedDevotionalDayId: (state, action: PayloadAction<number | null>) => {
            state.selectedDevotionalDayId = action.payload;
        },
        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
        }
    }
});

// Actions
export const {
    addCoverImageLayer,
    addDevotionalDay,
    addDevotionalDayElement,
    copyDevotionalDay,
    copySelectedCoverImageLayer,
    deleteDevotionalDay,
    deleteDevotionalElement,
    deleteSelectedCoverImageLayer,
    resetHistoryState,
    setCoverImage,
    setCoverImageBackgroundPosition,
    setCoverImageLayer,
    setCoverImageLayerPosition,
    setCoverImageSelectedLayer,
    setDevotional,
    setDevotionalDayElements,
    setDevotionalDays,
    setDevotionalDescription,
    setDevotionalElement,
    setDevotionalTags,
    setDevotionalTitle,
    setPublishingProfile,
    setSelectedDailyContentElementId,
    setSelectedDevotionalDayId,
    setStep
} = devotionalBuilderHistorySlice.actions;


// Selects
export const getSelectedCoverImageLayer = (state: RootState) => state.devotionalBuilderHistory.present.devotional?.coverImage?.layers?.find((layer, index) => index === state.devotionalBuilderHistory.present.coverImageSelectedLayer);

export const getSelectedDevotionalDay = (state: RootState) => state.devotionalBuilderHistory.present.devotional?.devotionalDays?.find((day, index) => day.id === state.devotionalBuilderHistory.present.selectedDevotionalDayId);

export default devotionalBuilderHistorySlice.reducer;