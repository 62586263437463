import * as React from 'react';
import './ImageElement.scss';

interface Props {
    src: string
}

export const ImageElement: React.FC<Props> = ({ src }) => {
    return (
        <img className="image-element" src={src} />
    )
}