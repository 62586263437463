import { GifSearch, ImageSearch } from 'grow.client';
import { isImageSearch } from '../../../typeGuards/ImageSearchTypeGuard';

interface Props {
    image: ImageSearch | GifSearch
    onClick: (photo: ImageSearch | GifSearch) => void
}

export const ImageSearchGridItem: React.FC<Props> = ({ image, onClick }) => {
    const isImage = isImageSearch(image)

    return (
        <div className="image-search-item" onClick={() => onClick(image)}>
            <img src={image.thumbnailUrl!} alt="" style={{ background: isImage ? image.color! : "#eeeeee" }} />
            {isImage &&
                <a
                    href={`${image.createdByPortfolioUrl!}?utm_source=Grow&utm_medium=referral`}
                    onClick={e => e.stopPropagation()}
                    className="creator-attribution"
                    target="_blank"
                    rel="noreferrer"
                >
                    {image.createdBy}
                </a>}
        </div>
    );
}