import { BusinessMemberCard } from '../../components/BusinessMemberCard/BusinessMemberCard';
import { UserProfileCard } from '../../components/UserProfileCard/UserProfileCard';
import { useSelector } from "../../store/store";
import Grid from "@mui/material/Grid";
import './HomePage.scss';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Add from "@mui/icons-material/Add";
import { CreateBusinessProfilePage } from '../CreateBusinessProfilePage/CreateBusinessProfilePage';
import { useEffect, useRef, useState } from 'react';
import Client, { BusinessMemberApproval, DevotionalSmall } from 'grow.client';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { TeamInvitePanel } from '../../components/TeamInvitePanel/TeamInvitePanel';
import { formatDate } from '../../functions/date';
import { history } from '../../functions/history';

export const HomePage = () => {
    const [tab, setTab] = useState("accounts");
    const [devotionals, setDevotionals] = useState<DevotionalSmall[] | null>(null);
    const [businessMemberApprovals, setBusinessMemberApprovals] = useState<BusinessMemberApproval[] | null>(null);
    const [disableDevotionalInfiniteScroll, setDisableDevotionalInfiniteScroll] = useState(false);
    const [disableBusinessMemberApprovalInfiniteScroll, setDisableBusinessMemberApprovalInfiniteScroll] = useState(false);
    const [showCreateBusinessProfileModal, setShowCreateBusinessProfileModal] = useState(false);
    const [devotionalToDelete, setDevotionalToDelete] = useState<number | null>(null);
    const userProfile = useSelector(state => state.user.userProfile);
    const businessMembers = useSelector(state => state.user.businessProfiles);
    const devotionalsCursor = useRef<number | null>(null);
    const businessMemberApprovalsCursor = useRef<number | null>(null);

    useEffect(() => {
        getDevotionalsPage();
        getTeamInvitesPage()
    }, []);

    const getDevotionalsPage = () => {
        const client = new Client();
        return client.getDraftDevotionals(devotionalsCursor.current).then((newDevotionals) => {
            const combinedDevotionals = [...(devotionals ?? []), ...newDevotionals.rows!];
            setDevotionals(combinedDevotionals);
            devotionalsCursor.current = newDevotionals.cursor;

            if (!newDevotionals.hasNextPage) {
                setDisableDevotionalInfiniteScroll(true);
            }
            return combinedDevotionals;
        })
    }

    const getTeamInvitesPage = () => {
        const client = new Client();
        return client.getUserBusinessMemberApprovals(businessMemberApprovalsCursor.current).then((newApprovals) => {
            const combinedApprovals = [...(businessMemberApprovals ?? []), ...newApprovals.rows!];
            setBusinessMemberApprovals(combinedApprovals);
            businessMemberApprovalsCursor.current = newApprovals.cursor;

            if (!newApprovals.hasNextPage) {
                setDisableBusinessMemberApprovalInfiniteScroll(true);
            }
            return combinedApprovals;
        })
    }

    const createDevotional = async () => {
        const client = new Client();
        const devotional = await client.createDevotional();
        history.push(`/devotional/${devotional.id}`);
    }

    const deleteDevotional = async () => {
        if (!devotionalToDelete) return;
        const client = new Client();
        await client.destroyDevotional(devotionalToDelete);
        setDevotionals(devotionals?.filter(d => d.id !== devotionalToDelete) ?? []);
        setDevotionalToDelete(null);
    }

    const removeTeamInvite = (id: number) => {
        setBusinessMemberApprovals(businessMemberApprovals?.filter(t => t.id !== id) ?? []);
    }

    return (
        <div id="home-page">
            <Container maxWidth="lg">
                <Box className="box">
                    <Tabs
                        value={tab}
                        onChange={(e, value) => setTab(value)}
                    >
                        <Tab label="Accounts" value="accounts" />
                        <Tab label="Draft Devotionals" value="devotionals" />
                        <Tab label="Team Invites" value="teamInvites" />
                    </Tabs>
                </Box>
                <div hidden={tab !== "accounts"}>
                    <Box className="box">
                        <Button
                            onClick={() => setShowCreateBusinessProfileModal(true)}
                            variant="contained"
                            startIcon={<Add />}
                            disableElevation={true}
                            className="create-business-account-button">
                            Create Business Account
                        </Button>
                    </Box>
                    <Grid container spacing={2}>
                        {userProfile &&
                            <Grid item xs={12} md={4}>
                                <UserProfileCard userProfile={userProfile} />
                            </Grid>}
                        {businessMembers.map(businessMember =>
                            <Grid item xs={12} md={4}>
                                <BusinessMemberCard businessMember={businessMember} />
                            </Grid>)}
                    </Grid>
                </div>
                <div hidden={tab !== "devotionals"}>
                    <Box className="box">
                        <Button
                            onClick={createDevotional}
                            variant="contained"
                            startIcon={<Add />}
                            disableElevation={true}
                            className="create-devotional-button">
                            Create Devotional
                        </Button>
                    </Box>
                    {devotionals &&
                        <InfiniteScroll
                            dataLength={devotionals?.length}
                            hasMore={!disableDevotionalInfiniteScroll}
                            next={getDevotionalsPage}
                            loader={<div>Loading...</div>}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Last Edited</TableCell>
                                            <TableCell width="180px"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {devotionals.map((devotional) => (
                                            <TableRow key={devotional.id}>
                                                <TableCell width="100px">
                                                    {devotional.image &&
                                                        <img src={devotional.image} alt={`${devotional.name} cover`} style={{ display: "block", width: 100 }} />}
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: "bold" }}>{devotional.name}</TableCell>
                                                <TableCell width="30%">{devotional.description}</TableCell>
                                                <TableCell>{formatDate(devotional.lastEdited)}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        onClick={() => history.push(`/devotional/${devotional.id}`)}
                                                        variant="contained"
                                                        disableElevation={true}>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => setDevotionalToDelete(devotional.id)}
                                                        sx={{ marginLeft: 1 }}
                                                        color="info"
                                                        variant="contained"
                                                        disableElevation={true}>
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </InfiniteScroll>}
                </div>
                <div hidden={tab !== "teamInvites"}>
                    {businessMemberApprovals &&
                        <InfiniteScroll
                            dataLength={businessMemberApprovals?.length}
                            hasMore={!disableBusinessMemberApprovalInfiniteScroll}
                            next={getTeamInvitesPage}
                            loader={<div>Loading...</div>}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Team</TableCell>
                                            <TableCell>Permissions</TableCell>
                                            <TableCell>Invited By</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {businessMemberApprovals.map((businessMemberApproval) => (
                                            <TeamInvitePanel
                                                businessMemberApproval={businessMemberApproval}
                                                onAction={removeTeamInvite} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </InfiniteScroll>}
                </div>
            </Container>
            <CreateBusinessProfilePage show={showCreateBusinessProfileModal} setShow={setShowCreateBusinessProfileModal} />
            <Dialog
                open={!!devotionalToDelete}
                onClose={() => setDevotionalToDelete(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Delete Devotional
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ whiteSpace: "pre-line" }}>
                        Are you sure you want to delete {devotionals?.find(d => d.id === devotionalToDelete)?.name ? <strong>{devotionals?.find(d => d.id === devotionalToDelete)?.name}</strong> : "this devotional"}? This cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteDevotional}>
                        OK
                    </Button>
                    <Button onClick={() => setDevotionalToDelete(null)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

