import Client, { DevotionalElement, SpotifyTrack } from 'grow.client';
import produce from 'immer';
import { useAppDispatch } from '../../../../../store/store';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import './MusicTrackSettings.scss';

interface Props {
    devotionalElement: DevotionalElement
    closeModal: () => void
}

export const MusicTrackSettings: React.FC<Props> = ({ devotionalElement, closeModal }) => {
    const [query, setQuery] = useState("");
    const [musicTracks, setMusicTracks] = useState<SpotifyTrack[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTrack, setSelectedTrack] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    // Load images on init and query change
    useEffect(() => {
        let isCancelled = false;

        const getMusicTracks = () => {
            const client = new Client();
            client.getTracks(query).then((tracks) => {
                if (isCancelled) return;
                setMusicTracks(tracks);
            }).finally(() => setIsLoading(false));
        }

        setIsLoading(true);
        const timeoutId = setTimeout(() => getMusicTracks(), 250);
        return () => {
            isCancelled = true;
            clearTimeout(timeoutId);
        }
    }, [query]);

    const selectTrack = async (musicTrack: SpotifyTrack) => {
        setSelectedTrack(musicTrack.id);
        const client = new Client();
        const track = await client.getTrack(musicTrack.id!);

        const updatedDevotionalElement = produce(devotionalElement, draft => {
            draft.musicElement = track;
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));

        setSelectedTrack(null);
        closeModal();
    }

    return (
        <div className="music-track-settings">
            <Typography>
                Powered by <Link underline={"none"} href="https://spotify.com" target="_blank">Spotify</Link>
            </Typography>
            <TextField
                label="Search"
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                onChange={e => setQuery(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                variant="standard"
            />
            {!isLoading && musicTracks?.map(musicTrack =>
                <React.Fragment>
                    <div
                        className="music-track-search-item"
                        onClick={() => selectTrack(musicTrack)}
                        key={musicTrack.id}>
                        <div className="album-art">
                            <img src={musicTrack.albumImage!} alt={`${musicTrack.name} album art`} />
                        </div>
                        <div className="info">
                            <Typography className="track-name">{musicTrack.name}</Typography>
                            <Typography className="artists" color="GrayText">{musicTrack.artists!.join(", ")}</Typography>
                        </div>
                        <div className="selected-loading">
                            {musicTrack.id === selectedTrack &&
                                <CircularProgress />}
                        </div>
                    </div>
                    <Divider sx={{ mb: 1 }} />
                </React.Fragment>)}
            {isLoading &&
                <div className="circular-progress-wrapper">
                    <CircularProgress />
                </div>}
        </div>
    );
}