import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"
import { Copyright } from "../../components/Copyright/Copyright"
import Client, {  } from "grow.client"
import { useAppDispatch } from "../../store/store"
import { setSnackbarOptions, setShowSnackBar } from '../../store/slices/uiSlice/uiSlice';

export const ForgottenPasswordPage = () => {
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useAppDispatch();

    const sendForgottenPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsSubmitting(true);

        const client = new Client();
        client.createResetPassword(email).then(() => {
            dispatch(setSnackbarOptions({
                message: `An email was sent to ${email} to reset your password.`,
                color: "success"
            }));
            dispatch(setShowSnackBar(true));
        }).finally(() => setIsSubmitting(false));
    }

    return (
        <Container id="sign-up-page" component="main" maxWidth="sm">
            <div className="paper">
                <Avatar className="avatar">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h3">
                    Locked out?
                </Typography>
                <p>Can't remember your password? Enter your email address and we'll send you a link to reset your password.</p>
                <form className="form" onSubmit={sendForgottenPassword}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={e => setEmail(e.target.value)}
                        inputProps={{
                            maxLength: 100
                        }} />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation={true}
                        className="submit lg-button"
                        disabled={!email}>
                        {!isSubmitting ? "Send Login Link" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}