import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { ProfileSearchInput } from '../../components/ProfileSearchInput/ProfileSearchInput';
import Client, { ApiException, BusinessMemberApprovalResource, ProfileSmall } from "grow.client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectProfileByUsername } from "../../store/slices/userSlice/userSlice";
import { useAppDispatch, useSelector } from '../../store/store';
import Button from "@mui/material/Button";
import { Add } from '@mui/icons-material';
import { setShowSnackBar, setSnackbarOptions } from "../../store/slices/uiSlice/uiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { labels } from '../../functions/labels';
import InfoIcon from '@mui/icons-material/Info';
import { addPendingMember } from "../../store/slices/memberSlice/memberSlice";

interface RouterParams {
    username: string
}

interface Props {
    show: boolean
    setShow: (show: boolean) => void
}

export const AddMemberModal: React.FC<Props> = ({ show, setShow }) => {
    const { username } = useParams<RouterParams>();
    const [addMemberProfile, setAddMemberProfile] = useState<ProfileSmall | null>(null);
    const [admin, setAdmin] = useState(false);
    const [devotionalCreator, setDevotionalCreator] = useState(false);
    const [devotionalCreatorWithReview, setDevotionalCreatorWithReview] = useState(false);
    const [proofReader, setProofReader] = useState(false);
    const [postCreator, setPostCreator] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const businessProfile = useSelector(selectProfileByUsername(username));

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (admin) {
            setDevotionalCreator(true);
            setDevotionalCreatorWithReview(true);
            setProofReader(true);
            setPostCreator(true);
        }
    }, [admin])

    const onClose = () => {
        setAdmin(false);
        setDevotionalCreator(false);
        setDevotionalCreatorWithReview(false);
        setProofReader(false);
        setPostCreator(false);
        setErrorMessage("");
        setAddMemberProfile(null);

        setShow(false);
    }

    const createNewMember = () => {
        if (!addMemberProfile || !businessProfile) return;

        const businessMemberApprovalResource: BusinessMemberApprovalResource = {
            businessProfileId: businessProfile.id,
            userProfileId: addMemberProfile.id,
            admin,
            devotionalCreator,
            devotionalCreatorWithReview,
            proofReader,
            postCreator
        };

        setIsSubmitting(true);
        setErrorMessage("");

        const client = new Client();
        client.createBusinessMemberApproval(businessMemberApprovalResource).then((newMemberApproval) => {
            dispatch(addPendingMember(newMemberApproval));
            dispatch(setSnackbarOptions({ message: `Request sent to ${addMemberProfile.name}.` }));
            dispatch(setShowSnackBar(true));
            onClose()
        }).catch((error: ApiException) => {
            const errorJson = JSON.parse(error.response);
            setErrorMessage(errorJson.Message);
        }).finally(() => setIsSubmitting(false))
    };

    return (
        <Modal
            open={show}
            onClose={() => onClose()}
            keepMounted={false}>
            <Box className="modal add-member-modal">
                <Typography variant="h6">
                    Add Member
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Search for a person to add as a new team member for {businessProfile?.name}.
                </Typography>
                <ProfileSearchInput value={addMemberProfile} setValue={setAddMemberProfile} />
                <div style={{ marginLeft: "10%" }}>
                    <div>
                        <FormControlLabel
                            label="Admin"
                            control={
                                <Switch
                                    checked={admin}
                                    onChange={(e) => setAdmin(e.target.checked)}
                                    color="primary" />}
                        />
                        <Tooltip title={labels.adminDescription} placement="top" arrow={true}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <div>
                        <FormControlLabel
                            label="Devotional Creator"
                            control={
                                <Switch
                                    checked={devotionalCreator}
                                    disabled={admin}
                                    onChange={(e) => setDevotionalCreator(e.target.checked)}
                                    color="primary" />}
                        />
                        <Tooltip title={labels.devotionalCreatorDescription} placement="top" arrow={true}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    {/* <div>
                        <FormControlLabel
                            label="Devotional Creator w/ Review"
                            control={
                                <Switch
                                    checked={devotionalCreatorWithReview}
                                    disabled={admin || devotionalCreator}
                                    onChange={(e) => setDevotionalCreatorWithReview(e.target.checked)}
                                    color="primary" />}
                        />
                        <Tooltip title={labels.devotionalCreatorWithReviewDescription} placement="top" arrow={true}>
                            <InfoIcon />
                        </Tooltip>
                    </div> */}
                    <div>
                        <FormControlLabel
                            label="Proof Reader"
                            control={
                                <Switch
                                    checked={proofReader}
                                    disabled={admin}
                                    onChange={(e) => setProofReader(e.target.checked)}
                                    color="primary" />}
                        />
                        <Tooltip title={labels.proofReaderDescription} placement="top" arrow={true}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <div>
                        <FormControlLabel
                            label="Post Creator"
                            control={
                                <Switch
                                    checked={postCreator}
                                    disabled={admin}
                                    onChange={(e) => setPostCreator(e.target.checked)}
                                    color="primary" />}
                        />
                        <Tooltip title={labels.postCreatorDescription} placement="top" arrow={true}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                </div>
                <Button
                    onClick={createNewMember}
                    sx={{ mt: 3, left: "50%", transform: "translate(-50%, 0)", width: 140 }}
                    variant="contained"
                    startIcon={!isSubmitting ? <Add /> : null}
                    disableElevation={true}
                    disabled={isSubmitting || (!addMemberProfile) || (!admin && !devotionalCreator && !devotionalCreatorWithReview && !proofReader && !postCreator)}
                    className="submit-new-member-button">
                    {!isSubmitting ? "Add Member" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                </Button>
                <Typography variant="body1" color="error" align="center" sx={{ mt: 2 }}>
                    {errorMessage}
                </Typography>
            </Box>
        </Modal>
    );
}