import InfoIcon from '@mui/icons-material/Info';
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Client, { ApiException, BusinessMember, BusinessMemberApproval } from "grow.client";
import { BusinessMemberPermission } from '../../types/BusinessMemberPermission';
import { GrowAvatar } from "../../components/GrowAvatar/GrowAvatar";
import { labels } from '../../functions/labels';
import store, { useAppDispatch, useSelector } from '../../store/store';
import { isBusinessMemberApproval } from '../../typeGuards/MemberTypeGuard';
import { selectBusinessMemberByUsername } from '../../store/slices/userSlice/userSlice';
import { useParams } from 'react-router';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useState } from 'react';
import React from 'react';
import { setMembers, setPendingMembers } from '../../store/slices/memberSlice/memberSlice';
import { setAlertOptions, setShowAlert } from '../../store/slices/uiSlice/uiSlice';

interface RouterParams {
    username: string
}

interface Props {
    members: BusinessMember[] | BusinessMemberApproval[]
    changePermission: (memberId: number, permission: BusinessMemberPermission, value: boolean) => void
}

export const MembersTable: React.FC<Props> = ({ members, changePermission }) => {
    const { username } = useParams<RouterParams>();
    const [memberToDelete, setMemberToDelete] = useState<BusinessMember | BusinessMemberApproval | null>(null)
    const businessMember = useSelector(selectBusinessMemberByUsername(username));
    const dispatch = useAppDispatch();

    const destroyMember = async (member: BusinessMember | BusinessMemberApproval | null) => {
        if (!member) return;

        const client = new Client();
        try {
            if (isBusinessMemberApproval(member)) {
                await client.destroyBusinessMemberApproval(member.id)
                const pendingMembers = store.getState().member.pendingMembers;
                if (pendingMembers)
                    dispatch(setPendingMembers(pendingMembers?.filter(m => m.id !== member.id)))
            }
            else {
                await client.destroyBusinessMember(member.id);
                const members = store.getState().member.members;
                if (members)
                    dispatch(setMembers(members?.filter(m => m.id !== member.id)))
            }

        } catch (error) {
            if (!(error instanceof ApiException)) return;
            const errorJson = JSON.parse(error.response);
            dispatch(setAlertOptions({
                header: "Failed to remove member",
                body: errorJson.Message
            }));
            dispatch(setShowAlert(true));
        } finally {
            setMemberToDelete(null);
        }
    }

    return (
        <React.Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Profile</TableCell>
                            <TableCell align="center">
                                Admin
                                <Tooltip title={labels.adminDescription} placement="top" arrow={true}>
                                    <InfoIcon />
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                                Devotional Creator
                                <Tooltip title={labels.devotionalCreatorDescription} placement="top" arrow={true}>
                                    <InfoIcon />
                                </Tooltip>
                            </TableCell>
                            {/* <TableCell align="center">
                            Devotional Creator w/ Review
                            <Tooltip title={labels.devotionalCreatorWithReviewDescription} placement="top" arrow={true}>
                                <InfoIcon />
                            </Tooltip>
                        </TableCell> */}
                            <TableCell align="center">
                                Post Creator
                                <Tooltip title={labels.postCreatorDescription} placement="top" arrow={true}>
                                    <InfoIcon />
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                                Proof Reader
                                <Tooltip title={labels.proofReaderDescription} placement="top" arrow={true}>
                                    <InfoIcon />
                                </Tooltip>
                            </TableCell>
                            {businessMember?.admin &&
                                <TableCell />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {members.map((member) => (
                            <TableRow key={member.id}>
                                <TableCell>
                                    <ListItem sx={{ padding: 0 }}>
                                        <ListItemAvatar>
                                            <GrowAvatar profile={member!.userProfile} />
                                        </ListItemAvatar>
                                        <ListItemText primary={member!.userProfile!.name} secondary={`@${member!.userProfile!.username}`} />
                                    </ListItem>
                                </TableCell>
                                <TableCell align="center">
                                    <Switch
                                        checked={member.admin}
                                        disabled={!businessMember?.admin}
                                        onChange={(e) => changePermission(member.id, "admin", e.target.checked)}
                                        color="primary" />
                                </TableCell>
                                <TableCell align="center">
                                    <Switch
                                        checked={member.devotionalCreator}
                                        disabled={member.admin || !businessMember?.admin}
                                        onChange={(e) => changePermission(member.id, "devotionalCreator", e.target.checked)}
                                        color="primary" />
                                </TableCell>
                                {/* <TableCell align="center">
                                <Switch
                                    checked={member.devotionalCreatorWithReview}
                                    disabled={member.admin || member.devotionalCreator || !businessMember?.admin}
                                    onChange={(e) => changePermission(member.id, "devotionalCreatorWithReview", e.target.checked)}
                                    color="primary" />
                            </TableCell> */}
                                <TableCell align="center">
                                    <Switch
                                        checked={member.postCreator}
                                        disabled={member.admin || !businessMember?.admin}
                                        onChange={(e) => changePermission(member.id, "postCreator", e.target.checked)}
                                        color="primary" />
                                </TableCell>
                                <TableCell align="center">
                                    <Switch
                                        checked={member.proofReader}
                                        disabled={member.admin || !businessMember?.admin}
                                        onChange={(e) => changePermission(member.id, "proofReader", e.target.checked)}
                                        color="primary" />
                                </TableCell>
                                {businessMember?.admin &&
                                    <TableCell align="center">
                                        <Button
                                            onClick={() => setMemberToDelete(member)}
                                            disableElevation={true}
                                            sx={{ marginRight: 1 }}
                                            variant="contained">
                                            Remove
                                        </Button>
                                    </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={!!memberToDelete}
                onClose={() => setMemberToDelete(null)}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Remove member
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ whiteSpace: "pre-line" }}>
                        Are you sure you want to remove {memberToDelete?.userProfile?.name} from being a member?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => destroyMember(memberToDelete)}>
                        OK
                    </Button>
                    <Button onClick={() => setMemberToDelete(null)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}