import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Slider from "@mui/material/Slider"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { setCoverImageLayer } from "../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice"
import { useAppDispatch, useSelector } from '../../../store/store';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import './CoverImageTextSettings.scss';
import { DevotionalCoverImageTextLayer, EDevotionalCoverImageTextLayerAlignment } from "grow.client"

interface Props {
    layer: DevotionalCoverImageTextLayer
}

export const CoverImageTextSettings: React.FC<Props> = ({ layer }) => {
    const index = useSelector(state => state.devotionalBuilderHistory.present.coverImageSelectedLayer);
    const fonts = useSelector(state => state.devotionalBuilder.fonts);
    const dispatch = useAppDispatch();

    const setText = (value: string) => {
        if (index === null) return;
        const updatedLayer: DevotionalCoverImageTextLayer = {
            ...layer,
            text: value
        };
        dispatch(setCoverImageLayer({ index, layer: updatedLayer }));
    }

    const setFontId = (value: number | string) => {
        if (index === null) return;
        if (typeof value === "string") return;
        const updatedLayer: DevotionalCoverImageTextLayer = {
            ...layer,
            fontId: value
        };
        dispatch(setCoverImageLayer({ index, layer: updatedLayer }));
    }

    const setFontSize = (value: number | number[]) => {
        if (index === null) return;
        const updatedLayer: DevotionalCoverImageTextLayer = {
            ...layer,
            fontSize: Array.isArray(value) ? value[0] : value
        };
        dispatch(setCoverImageLayer({ index, layer: updatedLayer }));
    }

    const setColor = (value: string) => {
        if (index === null) return;
        const updatedLayer: DevotionalCoverImageTextLayer = {
            ...layer,
            color: value
        };
        dispatch(setCoverImageLayer({ index, layer: updatedLayer }));
    }

    const setAlign = (value: EDevotionalCoverImageTextLayerAlignment) => {
        if (index === null) return;
        const updatedLayer: DevotionalCoverImageTextLayer = {
            ...layer,
            align: value
        };
        dispatch(setCoverImageLayer({ index, layer: updatedLayer }));
    }

    return (
        <div>
            <FormControl fullWidth margin="normal">
                <TextField
                    id="text"
                    label="Text"
                    onChange={(e) => setText(e.target.value)}
                    multiline
                    rows={3}
                    value={layer.text}
                    variant="standard" />
            </FormControl>
            <FormControl variant="standard" fullWidth margin="normal">
                <InputLabel id="font-label">Font</InputLabel>
                <Select
                    labelId="font-label"
                    id="font"
                    value={layer.fontId}
                    onChange={(e) => setFontId(e.target.value)}>
                    {fonts?.map(f =>
                        <MenuItem value={f.id} key={f.id} style={{ fontFamily: `'${f.family!}'` }}>{f.family}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Typography gutterBottom>
                    Font Size
                </Typography>
                <Slider
                    name="font-size"
                    onChange={(e, value) => setFontSize(value)}
                    value={layer.fontSize}
                    valueLabelDisplay="auto"
                    step={1}
                    min={1}
                    max={50} />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Typography gutterBottom>
                    Colour
                </Typography>
                <div className="button-options">
                    <div className="color-option black" onClick={() => setColor("#000000")} />
                    <div className="color-option white" onClick={() => setColor("#ffffff")} />
                </div>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Typography gutterBottom>
                    Text Align
                </Typography>
                <div className="button-options">
                    <div className="align-option" onClick={() => setAlign(EDevotionalCoverImageTextLayerAlignment.Left)}>
                        <FormatAlignLeftIcon />
                    </div>
                    <div className="align-option" onClick={() => setAlign(EDevotionalCoverImageTextLayerAlignment.Center)}>
                        <FormatAlignCenterIcon />
                    </div>
                    <div className="align-option" onClick={() => setAlign(EDevotionalCoverImageTextLayerAlignment.Right)}>
                        <FormatAlignRightIcon />
                    </div>
                </div>
            </FormControl>
        </div>
    )
}