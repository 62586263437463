import React, { useState } from 'react';
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { ArrowBack, Book, Create, Done, Home, PeopleAlt } from "@mui/icons-material";
import { EProfileType } from "grow.client";
import { useParams } from "react-router-dom";
import { selectProfileByUsername } from "../../store/slices/userSlice/userSlice";
import { history } from "../../functions/history";
import { useSelector } from "../../store/store";
import { GrowAvatar } from "../GrowAvatar/GrowAvatar";
import { EditProfilePage } from '../../pages/EditProfilePage/EditProfilePage';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import './Menu.scss';

interface RouterParams {
    username: string
}

export const Menu = () => {
    const { username } = useParams<RouterParams>();
    const profile = useSelector(selectProfileByUsername(username));
    const [profileMenuExpanded, setProfileMenuExpanded] = useState(false);
    const [showEditProfile, setShowEditProfile] = useState(false);

    const navigate = (path: string) => {
        history.push(path);
    }

    return (
        <Drawer
            className="menu"
            variant="permanent"
            sx={{
                width: 240,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
            }}>
            <Toolbar sx={{ padding: "0 10px" }} disableGutters>
                <IconButton
                    className="delete-day-button"
                    onClick={() => navigate(`/home`)}>
                    <ArrowBack />
                </IconButton>
            </Toolbar>
            <div className="drawer-container">
                <List>
                    <ListItem
                        sx={{ cursor: "pointer" }}
                        onClick={() => setProfileMenuExpanded(!profileMenuExpanded)}>
                        {profile &&
                            <ListItemAvatar>
                                <GrowAvatar profile={profile} />
                            </ListItemAvatar>}
                        {profile &&
                            <ListItemText primary={profile.name} secondary={`@${profile.username}`} />}
                        {profileMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={profileMenuExpanded}>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => setShowEditProfile(true)}>
                                <ListItemIcon><PersonIcon /></ListItemIcon>
                                <ListItemText>Edit Profile</ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <Divider />
                    {/* <ListItem button onClick={() => navigate(`/${profile?.username}/overview`)}>
                        <ListItemIcon><Home /></ListItemIcon>
                        <ListItemText>Overview</ListItemText>
                    </ListItem> */}
                    <ListItem button onClick={() => navigate(`/${profile?.username}/devotionals`)}>
                        <ListItemIcon><Book /></ListItemIcon>
                        <ListItemText>Devotionals</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => navigate(`/${profile?.username}/posts`)}>
                        <ListItemIcon><Create /></ListItemIcon>
                        <ListItemText>Posts</ListItemText>
                    </ListItem>
                    {profile?.type === EProfileType.Business &&
                        <React.Fragment>
                            <ListItem button onClick={() => navigate(`/${profile.username}/members`)}>
                                <ListItemIcon><PeopleAlt /></ListItemIcon>
                                <ListItemText>Members</ListItemText>
                            </ListItem>
                            <ListItem button onClick={() => navigate(`/${profile.username}/approval-requests`)}>
                                <ListItemIcon><Done /></ListItemIcon>
                                <ListItemText>Approval Requests</ListItemText>
                            </ListItem>
                        </React.Fragment>}
                </List>
            </div>
            {profile &&
                <EditProfilePage
                    profileUsername={profile.username!}
                    setShow={setShowEditProfile}
                    show={showEditProfile} />}
        </Drawer>
    );
}