import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { Copyright } from '../../components/Copyright/Copyright';
import './StartUpPage.scss';

const cards = [
    { id: 1, title: "🎥 Lights, Camera, Action", body: "With full support for embedding YouTube videos into your devotional, maybe it's time to break out the camera?" },
    { id: 2, title: "🎵 Hands Lifted High", body: "Want to share the latest hit worship song? With support for all the popular streaming services, consider it done." },
    { id: 3, title: "📷 Get Your Style On", body: "With a huge image and GIF library built in, making your devotionals look great has never been easier." },
    { id: 4, title: "📕 Bible for Days", body: "The days of copying and pasting Bible verses is long gone with us offering the whole Bible in 8 different translations." },
    { id: 5, title: "🔍 Get Yourself Discovered", body: "Tagging your devotionals to help people find them within the Grow app makes getting noticed easy." },
    { id: 6, title: "🍰 Piece of Cake", body: "Grow Studio's devotional builder has been made with ease in mind so you can spend more time creating and less time grinding." },
    { id: 7, title: "⚡ Lightning Fast", body: "Publishing your devotionals with no delay or waiting makes sharing your plans seamless. Perfect for quick turnarounds." },
    { id: 8, title: "📣 Engage your Community", body: "We provide links to your devotionals which can be shared with your community and help them discover your plans." },
    { id: 9, title: "📱 Social in Mind", body: "All devotionals support commenting, liking and sharing to help expand your reach and make an impact for Jesus." }
];

export const StartUpPage = () => {
    return (
        <div id="start-up-page">
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 10,
                    pb: 4
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Devotionals <span className="primary-color">Redefined.</span>
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        Create modern Bible plans for your church, friends and followers for the ultimate devotional experience in the Grow mobile app.
                    </Typography>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Button
                            className="submit lg-button"
                            color="primary"
                            component={Link}
                            to="/sign-up"
                            disableElevation={true}
                            variant="contained"
                        >
                            Sign up
                        </Button>
                        <Button
                            className="submit lg-button"
                            color="primary"
                            component={Link}
                            to="/login"
                            disableElevation={true}
                            variant="outlined"
                        >
                            Login
                        </Button>
                    </Stack>
                </Container>
            </Box>
            <Container className="features" sx={{ pt: 3, pb: 2 }} maxWidth="lg">
                <Grid container spacing={4} sx={{ py: 4 }}>
                    <Grid item md={6} sm={6} xs={12}>
                        <Typography variant="h3" color="text.secondary">
                            Super Easy.
                        </Typography>
                        <Typography variant="h3" color="text.primary" gutterBottom>
                            Out-of-the-box.
                        </Typography>
                        <Typography variant="h6" color="text.secondary" paragraph>
                            Grow Studio was built with simplicity in mind. We want you to get writing, building and creating with as little friction as possible.
                            With our drag and drop interface, building a devotional couldn't be easier.
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <img src="/assets/images/feature-image-1.jpg" alt="Grow Studio Devotional Builder" />
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ py: 4 }}>
                    <Grid item md={6} sm={6} xs={12} order={{ xs: 2, sm: 1 }}>
                        <img src="/assets/images/feature-image-2.jpg" alt="Grow Studio Cover Images" />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} order={{ xs: 1, sm: 2 }}>
                        <Typography variant="h3" color="text.secondary">
                            Hey There,
                        </Typography>
                        <Typography variant="h3" color="text.primary" gutterBottom>
                            Good Looking.
                        </Typography>
                        <Typography variant="h6" color="text.secondary" paragraph>
                            We're passionate about creating great looking devotional content so we've made sure it's both easy and fast for you to do just that.
                            Our cover image creator makes it possible to create a professional looking image for your plan in as little as 30 seconds.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ py: 4 }}>
                    <Grid item md={6} sm={6} xs={12}>
                        <Typography variant="h3" color="text.secondary">
                            Welcome to the
                        </Typography>
                        <Typography variant="h3" color="text.primary" gutterBottom>
                            21st Century.
                        </Typography>
                        <Typography variant="h6" color="text.secondary" paragraph>
                            Videos, worship songs and GIFs. We support them all so you can create the most dynamic and interactive devotional plans.
                            We believe engaging Bible content is so important for people to learn and keep coming back for more.
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <img src="/assets/images/feature-image-3.jpg" alt="Grow Studio Cover Images" />
                    </Grid>
                </Grid>
            </Container>
            <Container className="partners" sx={{ pb: 8 }} maxWidth="lg">
                <Grid container spacing={4} sx={{ pb: 2 }}>
                    <Grid item md={2} sm={4} xs={6}>
                        <img src="https://growfaith.co/wp-content/uploads/2022/04/us_primary_logo_black-1.png" alt="Urban Saints logo" />
                    </Grid>
                    <Grid item md={2} sm={4} xs={6}>
                        <img src="https://growfaith.co/wp-content/uploads/2022/04/CVM.jpg" alt="CVM Logo" />
                    </Grid>
                    <Grid item md={2} sm={4} xs={6}>
                        <img src="https://growfaith.co/wp-content/uploads/2022/04/harvest-city-logo.png" alt="Harvest City Church Logo" />
                    </Grid>
                    <Grid item md={2} sm={4} xs={6}>
                        <img src="https://growfaith.co/wp-content/uploads/2022/04/Get-Real-1.png" alt="Get Real Logo" />
                    </Grid>
                    <Grid item md={2} sm={4} xs={6}>
                        <img src="https://growfaith.co/wp-content/uploads/2022/04/cafod_logo.png" alt="CAFOD Logo" />
                    </Grid>
                    <Grid item md={2} sm={4} xs={6}>
                        <img src="https://growfaith.co/wp-content/uploads/2022/04/black_transparent_wide.png" alt="CAFOD Logo" />
                    </Grid>
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                        align="center"
                        color="text.secondary"
                        paragraph
                        maxWidth={500}>
                        Our partners trust Grow to provide an unrivalled experience for both themselves and their communities.
                    </Typography>
                </Box>
            </Container>
            <Container className="get-started" sx={{ pt: 2, pb: 4 }} maxWidth="lg">
                <Typography variant="h3" color="text.primary">
                    Get Started
                </Typography>
                <Typography variant="h5" color="text.secondary">
                    <ol>
                        <li>Create a personal Grow account for yourself.</li>
                        <li>If you want to create devotionals as a church, charity or band, create a business account.</li>
                        <li>Create your first devotional, it's super easy!</li>
                        <li>Publish your devotional so it's available on the <a href="https://growfaith.co" target="_blank" rel="noreferrer">Grow mobile app</a>.</li>
                        <li>Let your community know with our sharing links.</li>
                    </ol>
                </Typography>
            </Container>
            <Container sx={{ pt: 2, pb: 4 }} maxWidth="lg">
                <Grid container columnSpacing={4} rowSpacing={2}>
                    {cards.map((card) => (
                        <Grid item key={card.id} xs={12} sm={6} md={4}>
                            <Card
                                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                elevation={0}
                            >
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2" fontWeight={900}>
                                        {card.title}
                                    </Typography>
                                    <Typography color="text.secondary" fontSize={18}>
                                        {card.body}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Container>
            <Container sx={{ pb: 2 }}>
                <Copyright />
            </Container>
        </div>
    );
}