import Typography from '@mui/material/Typography';
import React from 'react';
import { createNewDailyContentCanvasElement } from '../../../functions/dailyContentElement';
import { DailyContentElement } from '../../../models/DailyContent/DailyContentElement';
import { addDevotionalDayElement, getSelectedDevotionalDay } from '../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import store, { useAppDispatch } from '../../../store/store';

interface Props {
    element: DailyContentElement
}

export const DailyContentToolBoxItem: React.FC<Props> = ({ element }) => {
    const dispatch = useAppDispatch();

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.dataTransfer.setData("type", "DailyContentToolboxItem");
        e.dataTransfer.setData("toolboxItem", element.type);
    }

    const onClick = () => {
        const devotionalDay = getSelectedDevotionalDay(store.getState());
        const newElementIndex = devotionalDay?.devotionalElements?.length ?? 0;
        const newDevotionalElement = createNewDailyContentCanvasElement(element.type, newElementIndex);

        dispatch(addDevotionalDayElement({ index: newElementIndex, devotionalElement: newDevotionalElement }))
    }

    return (
        <div
            className="daily-content-toolbox-item"
            draggable="true"
            onClick={onClick}
            onDragStart={onDragStart}
        >
            <element.icon />
            <Typography>{element.name}</Typography>
        </div>
    )
}