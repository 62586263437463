import { isCurrentUserProfile } from '../typeGuards/profileTypeGuards';
import Client, { FileParameter, Profile } from 'grow.client';
import { compressImageWithSetDimensions } from './image';

export const uploadProfileImage = async ( profile: Profile, fileBlob: Blob) => {
    const compressedImage = await compressImageWithSetDimensions(fileBlob, 300, 300, 1);
    const file: FileParameter = { data: compressedImage, fileName: "upload.jpg" };
    const client = new Client();

    if (isCurrentUserProfile(profile)) {
        await client.updateProfileImage(file);
    } else {
        await client.updateBusinessProfileImage(profile.id, file);
    }
}