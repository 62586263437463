import * as React from 'react';
import './TitleElement.scss';

interface Props {
    text: string
}

export const TitleElement: React.FC<Props> = ({ text }) => {
    return (
        <h1 className="title-element">
            {text}
        </h1>
    )
}