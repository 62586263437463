import { DevotionalElement, EHeaderElementType } from 'grow.client';
import FormControl from '@mui/material/FormControl';
import produce from 'immer';
import { useAppDispatch } from '../../../../../store/store';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface Props {
    devotionalElement: DevotionalElement
}

export const HeaderSettings: React.FC<Props> = ({ devotionalElement }) => {
    const dispatch = useAppDispatch();
    const headerElement = devotionalElement.headerElement;

    const setType = (value: string) => {
        const updatedDevotionalElement = produce(devotionalElement, draft => {
            if (!draft.headerElement) return;
            draft.headerElement.type = value as EHeaderElementType;
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));
    }

    return (
        <div>
            <FormControl variant="standard" fullWidth margin="normal">
                <InputLabel id="header-type-label">Type</InputLabel>
                <Select
                    labelId="header-type-label"
                    value={headerElement?.type ?? undefined}
                    onChange={(e) => setType(e.target.value)}>
                        <MenuItem value={EHeaderElementType.Message}>Message</MenuItem>
                        <MenuItem value={EHeaderElementType.Bible}>Bible</MenuItem>
                        <MenuItem value={EHeaderElementType.Prayer}>Prayer</MenuItem>
                        <MenuItem value={EHeaderElementType.Music}>Music</MenuItem>
                        <MenuItem value={EHeaderElementType.Reflection}>Reflection</MenuItem>
                        <MenuItem value={EHeaderElementType.Challenge}>Challenge</MenuItem>
                        <MenuItem value={EHeaderElementType.Declaration}>Declaration</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}