import React, { useEffect, useRef, useState } from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import HelpIcon from '@mui/icons-material/Help';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { CoverImageDesignerTab } from './CoverImageDesignerTab';
import { DailyContentTab } from './DailyContentTab';
import { PlanInformationTab } from './PlanInformationTab';
import { PublishTab } from './PublishTab';
import store, { useSelector } from "../../store/store";
import { useDispatch } from "react-redux";
import { resetState, setBibleFrame, setBibleTranslations, setFonts, setFontsLoaded, setIsPublishing, setIsSaving, setLastSavedDevotional, setPublishResult, setShowPublishMessage } from "../../store/slices/devotionalBuilderSlice/devotionalBuilderSlice";
import { resetHistoryState, setCoverImageSelectedLayer, setDevotional, setSelectedDevotionalDayId, setStep } from "../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice";
import { useParams } from 'react-router-dom';
import Client, { ApiException, DevotionalResource, EMediaType } from 'grow.client';
import WebFont from 'webfontloader';
import { history } from '../../functions/history';
import equal from 'deep-equal';
import './DevotionalBuilderPage.scss';
import { setShowSnackBar, setSnackbarOptions } from '../../store/slices/uiSlice/uiSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { compressImageWithSetDimensions, dataUritoBlob } from '../../functions/image';
import Konva from 'konva';
import { ActionCreators } from 'redux-undo';
import CircularProgress from '@mui/material/CircularProgress';
import { toJpeg } from 'html-to-image';
import { CoverImageCanvas } from '../../components/DevotionalBuilder/CoverImageCanvas/CoverImageCanvas';

interface RouterParams {
    devotionalId: string
}

export const DevotionalBuilderPage = () => {
    const { devotionalId } = useParams<RouterParams>();
    const [devotionalLoaded, setDevotionalLoaded] = useState(false);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const [triggeredSave, setTriggeredSave] = useState(false)
    const [triggeredPublish, setTriggeredPublish] = useState(false)
    const [showHelpModal, setShowHelpModal] = useState(false);
    const step = useSelector(state => state.devotionalBuilderHistory.present.step);
    const past = useSelector(state => state.devotionalBuilderHistory.past);
    const future = useSelector(state => state.devotionalBuilderHistory.future);
    const isSaving = useSelector(state => state.devotionalBuilder.isSaving);
    const coverImageSelectedLayer = useSelector(state => state.devotionalBuilderHistory.present.coverImageSelectedLayer);
    const allowUnsavedChanges = useRef(false);
    const unsavedChangesLocation = useRef("");
    const coverImageStageRef = useRef<Konva.Stage>(null);
    const coverImageStage2Ref = useRef<Konva.Stage>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetState());
        dispatch(resetHistoryState())

        const client = new Client();
        client.getDevotional(Number(devotionalId), null).then((devotional) => {
            dispatch(setDevotional(devotional));
            dispatch(setLastSavedDevotional(devotional));
            dispatch(setSelectedDevotionalDayId(devotional.devotionalDays![0].id ?? null))
            setDevotionalLoaded(true);
            dispatch(ActionCreators.clearHistory());
        });
    }, [devotionalId])

    useEffect(() => {
        const client = new Client();
        client.getFonts().then((fonts) => {
            dispatch(setFonts(fonts));
            WebFont.load({
                google: {
                    families: fonts.map(f => f.family!)
                },
                active: () => {
                    dispatch(setFontsLoaded(true));
                },
                timeout: 10000
            });
        });

        client.getBibleTranslations().then((bibleTranslations) => {
            dispatch(setBibleTranslations(bibleTranslations));
        })

        client.getBibleFrame().then(bibleFrame => {
            dispatch(setBibleFrame(bibleFrame));
        })
    }, [])

    useEffect(() => {
        const compareLastSaved = () => {
            const state = store.getState();
            const lastSavedDevotional = state.devotionalBuilder.lastSavedDevotional;
            const devotional = state.devotionalBuilderHistory.present.devotional;
            const areEqual = equal(lastSavedDevotional, devotional);
            return areEqual;
        }

        const beforeUnload = (e: BeforeUnloadEvent) => {
            if (compareLastSaved()) {
                return undefined;
            }

            const confirmationMessage = "It looks like you have some unsaved changes. Make sure to save before leaving this page to not lose your changes.";
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        }

        const unblock = history.block(targetLocation => {
            const areEqual = compareLastSaved();
            if (!areEqual && !allowUnsavedChanges.current) {
                unsavedChangesLocation.current = targetLocation.pathname;
                setShowUnsavedChangesDialog(true);
                return false;
            }
        });

        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            unblock();
            window.removeEventListener("beforeunload", beforeUnload);
        }
    }, [])

    useEffect(() => {
        // Wait for cover image to deselect all layers
        if (coverImageSelectedLayer || !(triggeredSave || triggeredPublish)) return;

        const saveDevotional = async () => {
            const state = store.getState();
            const devotional = state.devotionalBuilderHistory.present.devotional;
            if (!devotional) return;

            const devotionalResource: DevotionalResource = {
                ...devotional,
                coverImageMediaId: null
            }

            const client = new Client();
            try {
                if (coverImageStage2Ref.current?.attrs.width !== 0) {
                    const pixelRatio = 1920 / coverImageStage2Ref.current!.width();

                    const node = document.querySelector<HTMLElement>(".hidden-cover-image .konvajs-content");

                    // Fix for safari not working first time. Keep rerendering until two jpegs come out as the same
                    let earlierJpeg: string | null = null;
                    for (let index = 0; index < 5; index++) {
                        const newJpeg = await toJpeg(node!, { pixelRatio, skipFonts: true, fontEmbedCSS: "", preferredFontFormat: "svg" });
                        if (earlierJpeg === newJpeg) {
                            break;
                        }
                        earlierJpeg = newJpeg;
                    }

                    const dataUri = await toJpeg(node!, { pixelRatio, skipFonts: true, fontEmbedCSS: "", preferredFontFormat: "svg" });
                    if (!dataUri) throw new Error();

                    const image = dataUritoBlob(dataUri);
                    const compressedImage = await compressImageWithSetDimensions(image, 1920, 1080);

                    const media = await client.uploadMedia(EMediaType.DevotionalCoverImage, {
                        data: compressedImage,
                        fileName: "upload.jpg"
                    });

                    devotionalResource.coverImageMediaId = media.id
                }

                const promise = triggeredSave ? client.updateDevotional(Number(devotionalId), devotionalResource) : client.publishDevotional(Number(devotionalId), devotionalResource);
                await promise;
                if (triggeredSave) {
                    dispatch(setSnackbarOptions({ message: "Successfully saved Devotional." }))
                    dispatch(setShowSnackBar(true));
                } else if (triggeredPublish) {
                    dispatch(setPublishResult({
                        message: "Devotional successfully published! Please return back to the Grow Studio home.",
                        success: true
                    }));
                    dispatch(setShowPublishMessage(true))
                }

                dispatch(setLastSavedDevotional(devotional));
            } catch (error) {
                let message = "";
                if (error instanceof ApiException) {
                    message = JSON.parse(error.response).Message;
                } else if (error instanceof Error) {
                    message = error.message;
                }

                if (triggeredSave) {
                    dispatch(setSnackbarOptions({ message: `Failed to save Devotional! Please try again. ${message ? message : ""}`, color: "error" }))
                    dispatch(setShowSnackBar(true));
                } else if (triggeredPublish) {
                    const errorJson = JSON.parse((error as ApiException).response);
                    dispatch(setPublishResult({
                        message: `Please resolve the following issues: \n\n${errorJson.Message}`,
                        success: false
                    }));
                    dispatch(setShowPublishMessage(true))
                }
            } finally {
                dispatch(setIsSaving(false));
                dispatch(setIsPublishing(false));
            }
        }

        setTriggeredSave(false);
        setTriggeredPublish(false);
        saveDevotional();

    }, [coverImageSelectedLayer, triggeredSave, triggeredPublish])

    const changeStep = (stepIndex: number) => {
        dispatch(setStep(stepIndex));
    }

    const undo = () => {
        dispatch(ActionCreators.undo());
    }

    const redo = () => {
        dispatch(ActionCreators.redo());
    }

    const prepareSaveDevotional = async () => {
        dispatch(setCoverImageSelectedLayer(null));
        dispatch(setIsSaving(true));
        setTriggeredSave(true);
    }

    const preparePublishDevotional = async () => {
        dispatch(setCoverImageSelectedLayer(null));
        dispatch(setIsPublishing(true));
        setTriggeredPublish(true);
    }

    const steps = ["Plan Information", "Cover Image", "Daily Content", "Publish"];
    let bodyContainerClass = "plan-information";
    if (step === 1) bodyContainerClass = "cover-image-designer";
    else if (step === 2) bodyContainerClass = "daily-content";
    else if (step === 3) bodyContainerClass = "publish";

    if (!devotionalLoaded) return null;

    return (
        <div id="devotional-builder-page" className={bodyContainerClass}>
            <div className="header-buttons">
                <IconButton
                    className="save-devotional-button"
                    onClick={prepareSaveDevotional}
                    disabled={isSaving}>
                    {!isSaving ? <SaveIcon /> : <CircularProgress size={24} />}
                </IconButton>
                <IconButton
                    disabled={past.length === 0}
                    className="undo-button"
                    onClick={undo}>
                    <UndoIcon />
                </IconButton>
                <IconButton
                    disabled={future.length === 0}
                    className="redo-button"
                    onClick={redo}>
                    <RedoIcon />
                </IconButton>
                <IconButton
                    className="help-button"
                    onClick={() => setShowHelpModal(true)}>
                    <HelpIcon />
                </IconButton>
            </div>
            <div className="body-container">
                <Container maxWidth="xl">
                    <div className="builder-flow">
                        <Stepper nonLinear activeStep={step}>
                            {steps.map((step, index) =>
                                <Step key={step}>
                                    <StepButton onClick={() => changeStep(index)}>
                                        {step}
                                    </StepButton>
                                </Step>
                            )}
                            <Step>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className="next-step-button"
                                    onClick={() => changeStep(step + 1)}
                                    disabled={step === 3}>
                                    {step === 3 ?
                                        <React.Fragment>Complete <CheckIcon sx={{ marginLeft: 0.5 }} /></React.Fragment> :
                                        <React.Fragment>Next Step <ArrowForwardOutlinedIcon sx={{ marginLeft: 0.5 }} /></React.Fragment>}
                                </Button>
                            </Step>
                        </Stepper>
                    </div>
                </Container>
                <div className="tab-padding-top" hidden={step !== 0}>
                    <PlanInformationTab />
                </div>
                <div className="tab-padding-top" hidden={step !== 1}>
                    <CoverImageDesignerTab coverImageStageRef={coverImageStageRef} />
                </div>
                <div hidden={step !== 2}>
                    <DailyContentTab />
                </div>
                <div className="tab-padding-top" hidden={step !== 3}>
                    <PublishTab publishDevotional={preparePublishDevotional} />
                </div>
            </div>
            <div className="hidden-cover-image">
                <CoverImageCanvas coverImageStageRef={coverImageStage2Ref} />
            </div>
            <Dialog
                open={showUnsavedChangesDialog}
                onClose={() => setShowUnsavedChangesDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Unsaved Changes
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        It looks like you have some unsaved changes. Make sure to save before leaving this page to not lose your changes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        allowUnsavedChanges.current = true;
                        setShowUnsavedChangesDialog(false);
                        history.push(unsavedChangesLocation.current);
                    }}>
                        Leave
                    </Button>
                    <Button onClick={() => setShowUnsavedChangesDialog(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <h3 style={{margin: 0}}>Help</h3>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Looking to create a devotional, but don't know where to start? Watch our video below that walks you through the process.
                        <br />
                        <br />
                        Take your devotionals to the next level by checking out our <a href="https://growfaith.co/wp-content/uploads/2022/04/How-to-write-a-good-devotional.pdf">tips for creating a good devotional</a>.
                    </DialogContentText>
                    <br />
                    <video controls width="100%">
                        <source src="https://growfaith.co/wp-content/uploads/2022/04/Grow-Studio-Creating-a-Devotional.mp4" type="video/mp4" />
                    </video>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowHelpModal(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
