import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Add from '@mui/icons-material/Add';
import Client, { DevotionalSmall, EProfileType } from "grow.client";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { selectProfileByUsername } from "../../store/slices/userSlice/userSlice";
import { useSelector } from "../../store/store";
import { history } from "../../functions/history";
import { formatDate } from '../../functions/date';
import { GrowAvatar } from "../../components/GrowAvatar/GrowAvatar";
import './DevotionalsPage.scss';

interface RouterParams {
    username: string
}

export const DevotionalsPage = () => {
    const { username } = useParams<RouterParams>();
    const [devotionals, setDevotionals] = useState<DevotionalSmall[] | null>(null);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
    const profile = useSelector(selectProfileByUsername(username));
    const devotionalsCursor = useRef<number | null>(null);

    useEffect(() => {
        getDevotionalsPage();
    }, [username])

    const getDevotionalsPage = () => {
        if (!profile) return;

        const client = new Client();
        return client.getProfileDevotionals(profile.id, devotionalsCursor.current, null).then((newDevotionals) => {
            const combinedDevotionals = [...(devotionals ?? []), ...newDevotionals.rows!];
            setDevotionals(combinedDevotionals);
            devotionalsCursor.current = newDevotionals.cursor;

            if (!newDevotionals.hasNextPage) {
                setDisableInfiniteScroll(true);
            }
            return combinedDevotionals;
        })
    }

    const createDevotional = async () => {
        const client = new Client();
        const devotional = await client.createDevotional();
        history.push(`/devotional/${devotional.id}`);
    }

    return (
        <Box id="devotionals-page" component="main">
            <Box className="box">
                <Button
                    onClick={createDevotional}
                    variant="contained"
                    startIcon={<Add />}
                    disableElevation={true}
                    className="create-devotional-button">
                    Create Devotional
                </Button>
            </Box>
            <Typography variant="h4">
                Devotionals
            </Typography>
            {devotionals &&
                <InfiniteScroll
                    dataLength={devotionals?.length}
                    hasMore={!disableInfiniteScroll}
                    next={getDevotionalsPage}
                    loader={<div>Loading...</div>}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Description</TableCell>
                                    {profile?.type === EProfileType.Business &&
                                        <TableCell>Creator</TableCell>}
                                    {profile?.type === EProfileType.User &&
                                        <TableCell>Published As</TableCell>}
                                    <TableCell>Last Edited</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {devotionals.map((devotional) => (
                                    <TableRow key={devotional.id}>
                                        <TableCell width="100px">
                                            <img src={devotional.image!} alt={`${devotional.name} cover`} style={{ display: "block", width: 100 }} />
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>{devotional.name}</TableCell>
                                        <TableCell width="30%">{devotional.description}</TableCell>
                                        <TableCell>
                                            {profile?.type === EProfileType.Business &&
                                                <ListItem sx={{ padding: 0 }}>
                                                    <ListItemAvatar>
                                                        <GrowAvatar profile={devotional.userProfile!} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={devotional.userProfile!.name} secondary={`@${devotional.userProfile!.username}`} />
                                                </ListItem>}
                                            {profile?.type === EProfileType.User &&
                                                <ListItem sx={{ padding: 0 }}>
                                                    <ListItemAvatar>
                                                        <GrowAvatar profile={devotional.businessProfile ?? devotional.userProfile} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={(devotional.businessProfile ?? devotional.userProfile!).name}
                                                        secondary={`@${(devotional.businessProfile ?? devotional.userProfile!).username}`} />
                                                </ListItem>}
                                        </TableCell>
                                        <TableCell>{formatDate(devotional.lastEdited)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>}
        </Box>
    );
}