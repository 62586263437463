import Compressor from 'compressorjs';

export const compressImage = (file: Blob, maxWidth: number, maxHeight: number, cropAspectRatio: number | null = null) => {
    return new Promise<Blob>(async (resolve, reject) => {
        let blob: Blob = file;
        if (cropAspectRatio) {
            blob = await crop(file, cropAspectRatio);
        }

        new Compressor(blob, {
            maxHeight,
            maxWidth,
            quality: 0.3,
            mimeType: "image/jpeg",
            success: resolve,
            error: reject,
        });
    })
}

export const compressImageWithSetDimensions = (file: Blob, width: number, height: number, cropAspectRatio: number | null = null) => {
    return new Promise<Blob>(async (resolve, reject) => {
        let blob: Blob = file;
        if (cropAspectRatio) {
            blob = await crop(file, cropAspectRatio);
        }

        new Compressor(blob, {
            height,
            width,
            quality: 0.3,
            mimeType: "image/jpeg",
            success: resolve,
            error: reject,
        });
    })
}

export const dataUritoBlob = (dataUri: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataUri.split(',')[1]);

    // separate out the mime component
    var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
}

const crop = (file: Blob, aspectRatio: number): Promise<Blob> => {

    // we return a Promise that gets resolved with our canvas element
    return new Promise((resolve, reject) => {

        // this image will hold our source image data
        const inputImage = new Image();

        // we want to wait for our image to load
        inputImage.onload = () => {

            // let's store the width and height of our image
            const inputWidth = inputImage.naturalWidth;
            const inputHeight = inputImage.naturalHeight;

            // get the aspect ratio of the input image
            const inputImageAspectRatio = inputWidth / inputHeight;

            // if it's bigger than our target aspect ratio
            let outputWidth = inputWidth;
            let outputHeight = inputHeight;
            if (inputImageAspectRatio > aspectRatio) {
                outputWidth = inputHeight * aspectRatio;
            } else if (inputImageAspectRatio < aspectRatio) {
                outputHeight = inputWidth / aspectRatio;
            }

            // calculate the position to draw the image at
            const outputX = (outputWidth - inputWidth) * .5;
            const outputY = (outputHeight - inputHeight) * .5;

            // create a canvas that will present the output image
            const outputImage = document.createElement('canvas');

            // set it to the same size as the image
            outputImage.width = outputWidth;
            outputImage.height = outputHeight;

            // draw our image at position 0, 0 on the canvas
            const ctx = outputImage.getContext('2d');
            ctx!.drawImage(inputImage, outputX, outputY);

            const blobCallback = (blob: Blob | null) => {
                blob ? resolve(blob) : reject(blob);
            }

            outputImage.toBlob(blobCallback, 'image/jpg')
        };

        // start loading our image
        const reader = new FileReader();
        reader.onload = function (event) {
            inputImage.src = event.target!.result as string;
        };

        reader.readAsDataURL(file);
    })
}