import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SnackbarOptions {
    message: string
    color?: AlertColor
}

export interface AlertOptions {
    header: string
    body: string
}

export interface sliceState {
    alertOptions: AlertOptions | null
    showAlert: boolean
    showSnackbar: boolean
    snackBarOptions: SnackbarOptions | null
}

const initialState: sliceState = {
    alertOptions: null,
    showAlert: false,
    showSnackbar: false,
    snackBarOptions: null
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setAlertOptions: (state, action: PayloadAction<AlertOptions>) => {
            state.alertOptions = action.payload;
        },
        setShowAlert: (state, action: PayloadAction<boolean>) => {
            state.showAlert = action.payload;
        },
        setShowSnackBar: (state, action: PayloadAction<boolean>) => {
            state.showSnackbar = action.payload;
        },
        setSnackbarOptions: (state, action: PayloadAction<SnackbarOptions>) => {
            state.snackBarOptions = action.payload;
        }
    }
});

// Actions
export const { setAlertOptions, setShowAlert, setShowSnackBar, setSnackbarOptions } = uiSlice.actions;

export default uiSlice.reducer;