import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { PostAttachment } from '../../components/PostAttachment/PostAttachment';
import { useEffect, useRef, useState } from "react";
import Client, { Post } from "grow.client";
import store from "../../store/store";
import { selectProfileByUsername } from "../../store/slices/userSlice/userSlice";
import { useParams } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDate } from "../../functions/date";
import './PostsPage.scss';

interface RouterParams {
    username: string
}

export const PostsPage = () => {
    const { username } = useParams<RouterParams>();
    const [posts, setPosts] = useState<Post[] | null>(null);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
    const postsCursor = useRef<number | null>(null);

    useEffect(() => {
        getPostsPage();
    }, [username])

    const getPostsPage = () => {
        const profile = selectProfileByUsername(username)(store.getState());
        if (!profile) return;

        const client = new Client();
        return client.getProfileFeed(profile.id, postsCursor.current, null).then((newPosts) => {
            const combinedPosts = [...(posts ?? []), ...newPosts.rows!];
            setPosts(combinedPosts);
            postsCursor.current = newPosts.cursor;

            if (!newPosts.hasNextPage) {
                setDisableInfiniteScroll(true);
            }
            return combinedPosts;
        })
    }

    return (
        <Box id="posts-page" component="main">
            <Typography variant="h4">
                Posts
            </Typography>
            {posts &&
                <InfiniteScroll
                    dataLength={posts?.length}
                    hasMore={!disableInfiniteScroll}
                    next={getPostsPage}
                    loader={<div>Loading...</div>}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Text</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell>Likes</TableCell>
                                    <TableCell>Comments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {posts.map((post) => (
                                    <TableRow key={post.id}>
                                        <TableCell>
                                            <PostAttachment post={post} />
                                        </TableCell>
                                        <TableCell>{post.text}</TableCell>
                                        <TableCell>{formatDate(post.created)}</TableCell>
                                        <TableCell>{post.likeCount}</TableCell>
                                        <TableCell>{post.commentCount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>}
        </Box>
    );
}