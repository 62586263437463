import moment from 'moment';
import 'moment/min/locales.min';

export const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleString(navigator.language, { dateStyle: "medium", timeStyle: "medium" });
    return formattedDate;
}

export const getShortDateFormat = () => {
    moment.locale(navigator.language);
    const localeData = moment.localeData(navigator.language);
    const dateFormat = localeData.longDateFormat('L');
    return dateFormat;
}