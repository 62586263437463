import Client, { DevotionalElement } from 'grow.client';
import produce from 'immer';
import { useAppDispatch, useSelector } from '../../../../../store/store';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import './BibleSettings.scss';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

interface Props {
    devotionalElement: DevotionalElement
}

export const BibleSettings: React.FC<Props> = ({ devotionalElement }) => {
    const bibleFrame = useSelector(state => state.devotionalBuilder.bibleFrame);
    const dispatch = useAppDispatch();
    const bibleElement = devotionalElement.bibleElement;
    const book = bibleFrame?.books!.find(b => b.name === bibleElement?.book);
    const chapter = book?.chapters!.find(c => c.number === bibleElement?.chapter);

    useEffect(()=> {
        if (!bibleElement?.book || !bibleElement.chapter || !bibleElement.firstVerse) return;

        const client = new Client();
        client.getScripture(bibleElement.book, bibleElement.chapter, bibleElement.firstVerse, bibleElement.lastVerse, bibleElement.translationCode).then((scripture) => {
            const updatedDevotionalElement = produce(devotionalElement, draft => {
                draft.bibleElement = scripture;
            });
            dispatch(setDevotionalElement(updatedDevotionalElement));  
        });

    }, [bibleElement?.book, bibleElement?.chapter, bibleElement?.firstVerse, bibleElement?.lastVerse])

    const setBook = (value: string) => {
        const updatedDevotionalElement = produce(devotionalElement, draft => {
            if (!draft.bibleElement) return;
            draft.bibleElement.book = value;
            draft.bibleElement.firstVerse = 0;
            draft.bibleElement.lastVerse = null;
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));
    }

    const setChapter = (value: number) => {
        const updatedDevotionalElement = produce(devotionalElement, draft => {
            if (!draft.bibleElement) return;
            draft.bibleElement.chapter = value;
            draft.bibleElement.firstVerse = 0;
            draft.bibleElement.lastVerse = null;
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));
    }

    const setVerse = (value: number) => {
        if (!devotionalElement.bibleElement) return;

        const firstVerse = bibleElement?.firstVerse;
        const lastVerse = bibleElement?.lastVerse;
        let updatedDevotionalElement: DevotionalElement | null = null;

        if (!firstVerse) {
            updatedDevotionalElement = produce(devotionalElement, draft => {
                draft.bibleElement!.firstVerse = value;
            });
        } else if (lastVerse === firstVerse) {
            if (firstVerse >= value) {
                updatedDevotionalElement = produce(devotionalElement, draft => {
                    draft.bibleElement!.firstVerse = value;
                    draft.bibleElement!.lastVerse = value;
                });
            } else {
                updatedDevotionalElement = produce(devotionalElement, draft => {
                    draft.bibleElement!.lastVerse = value;
                });
            }
        } else {
            updatedDevotionalElement = produce(devotionalElement, draft => {
                draft.bibleElement!.firstVerse = value;
                draft.bibleElement!.lastVerse = value;
            });
        }
        dispatch(setDevotionalElement(updatedDevotionalElement));
    }

    return (
        <div className="bible-settings">
            <Grid container columnSpacing={2}>
                <Grid item xs={4}>
                    <Typography variant="h6">Book</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6">Chapter</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6">Verses</Typography>
                </Grid>
                <Grid item xs={4} className="scrollable-column">
                    <List>
                        {bibleFrame?.books?.map(book =>
                            <ListItemButton
                                selected={book.name === bibleElement?.book}
                                onClick={() => setBook(book.name!)}>
                                <ListItemText primary={book.name} />
                            </ListItemButton>)}
                    </List>
                </Grid>
                <Grid item xs={4} className="scrollable-column">
                    <div className="circular-buttons">
                        {book && book.chapters?.map(c =>
                            <div
                                className={`circular-button ${c.number === chapter?.number ? "selected" : ""}`}
                                onClick={() => setChapter(c.number)}>
                                {c.number}
                            </div>)}
                    </div>
                </Grid>
                <Grid item xs={4} className="scrollable-column">
                    <div className="circular-buttons">
                        {chapter && [...Array(chapter.lastVerse)].map((v, index) => {
                            let classes = "";
                            index += 1;
                            if (index === bibleElement?.firstVerse) {
                                classes = !!bibleElement.lastVerse && bibleElement.lastVerse !== bibleElement.firstVerse ? "first-verse" : "selected";
                            } else if (index === bibleElement?.lastVerse) {
                                classes = "last-verse"
                            } else if (bibleElement?.firstVerse && bibleElement?.lastVerse && index > bibleElement?.firstVerse && index < bibleElement?.lastVerse) {
                                classes = "inbetween-verse";
                            }

                            return (
                                <div
                                    className={`circular-button ${classes}`}
                                    onClick={() => setVerse(index)}>
                                    {index}
                                </div>
                            );
                        })}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}