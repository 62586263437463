import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector, } from 'react-redux';
import devotionalBuilderReducer from './slices/devotionalBuilderSlice/devotionalBuilderSlice';
import devotionalBuilderHistoryReducer from './slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import membersReducer from './slices/memberSlice/memberSlice';
import uiReducer from './slices/uiSlice/uiSlice';
import userReducer from './slices/userSlice/userSlice';
import undoable, { groupByActionTypes } from 'redux-undo';

const store = configureStore({
    reducer: {
        devotionalBuilder: devotionalBuilderReducer,
        devotionalBuilderHistory: undoable(devotionalBuilderHistoryReducer, {
            groupBy: groupByActionTypes([
                "devotionalBuilderHistory/setDevotionalDescription",
                "devotionalBuilderHistory/setDevotionalTitle",
                "devotionalBuilderHistory/setCoverImageBackgroundPosition",
                "devotionalBuilderHistory/setCoverImageLayerPosition",
                "devotionalBuilderHistory/setCoverImageLayer",
                "devotionalBuilderHistory/setCoverImage",
                "devotionalBuilderHistory/setDevotionalElement"
            ])
        }),
        member: membersReducer,
        ui: uiReducer,
        user: userReducer
    }
})

export default store;

export type RootState = ReturnType<typeof store.getState>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();