import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { PaperAvatar } from '../../../components/PaperAvatar/PaperAvatar';
import BuildIcon from '@mui/icons-material/Build';
import { dailyContentElements } from '../../../global/dailyContentElements';
import { DailyContentToolBoxItem } from './DailyContentToolBoxItem';
import { groupBy } from '../../../functions/groupBy';
import './DailyContentToolBox.scss';

export const DailyContentToolBox = () => {
    const elements = groupBy(dailyContentElements, e => e.group);

    return (
        <Container className="paper-panel daily-content-toolbox" sx={{ marginTop: 0 }}>
            <PaperAvatar color="#008b8b">
                <BuildIcon />
            </PaperAvatar>
            <Paper id="daily-content-toolbox-modal" className="paper-modal" elevation={10}>
                <Typography variant="h5">Toolbox</Typography>
                <div>
                    <div className="daily-content-toolbox-group">
                        <Typography variant="h6">Text</Typography>
                        <div className="daily-content-toolbox-group-buttons">
                            {elements.get("text")?.map((e) =>
                                <DailyContentToolBoxItem element={e} key={e.name} />)}
                        </div>
                    </div>
                    <div className="daily-content-toolbox-group">
                        <Typography variant="h6">Multi-Media</Typography>
                        <div className="daily-content-toolbox-group-buttons">
                            {elements.get("multi-media")?.map((e) =>
                                <DailyContentToolBoxItem element={e} key={e.name} />)}
                        </div>
                    </div>
                </div>
            </Paper>
        </Container>
    )
}