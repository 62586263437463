import * as React from 'react';
import { MusicTrack } from 'grow.client';
import { Collapse } from 'react-collapse';
import { IonButton, IonIcon } from '@ionic/react';
import { YouTubeFrame } from '../YouTubeFrame/YouTubeFrame';
import { chevronDownSharp, chevronUpSharp } from 'ionicons/icons';
import Skeleton from 'react-loading-skeleton';
import './MusicTrackPanel.scss';

interface Props {
    musicTrack: MusicTrack
}

interface MusicServiceLink {
    name: string
    imageUrl: string
    linkUrl: string | null
}

export const MusicTrackPanel: React.FC<Props> = ({ musicTrack }) => {
    const [showYouTubeVideo, setShowYouTubeVideo] = React.useState(false);
    const [showLinks, setShowLinks] = React.useState(false);
    const [hasThumbnailLoaded, setHasThumbnailLoaded] = React.useState(false);
    const youTubeVideoId = musicTrack.youTubeUrl ? new URL(musicTrack.youTubeUrl).searchParams.get("v") : null;
    const artists = musicTrack.artists!.join(", ");

    const musicServiceLinks: MusicServiceLink[] = [
        {
            name: "YouTube",
            imageUrl: "/assets/images/music-services/youtube.svg",
            linkUrl: musicTrack.youTubeUrl
        },
        {
            name: "Spotify",
            imageUrl: "/assets/images/music-services/spotify.svg",
            linkUrl: musicTrack.spotifyUrl
        },
        {
            name: "Apple Music",
            imageUrl: "/assets/images/music-services/apple-music.svg",
            linkUrl: musicTrack.appleMusicUrl
        },
        {
            name: "Amazon Music",
            imageUrl: "/assets/images/music-services/amazon-music.svg",
            linkUrl: musicTrack.amazonMusicUrl
        },
    ].filter(m => m.linkUrl); // Remove any services without a link

    const toggleYouTubeFrame = () => {
        setShowYouTubeVideo(!showYouTubeVideo);
    }

    const toggleLinks = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setShowLinks(!showLinks);
    }

    const openMusicServiceUrl = (url: string) => {
        window.open(url, "_blank");
    }

    const onThumbnailLoad = () => {
        setHasThumbnailLoaded(true);
    }

    return (
        <div className="music-track-panel">
            {youTubeVideoId &&
                <Collapse isOpened={showYouTubeVideo}>
                    <YouTubeFrame videoId={youTubeVideoId} />
                </Collapse>}
            <div
                className={"track-contents " + (showYouTubeVideo ? "sm" : "")}
                // onClick={toggleYouTubeFrame}
            >
                <div className="album-art">
                    <img
                        src={musicTrack.albumArtUrl!}
                        style={{ display: hasThumbnailLoaded ? "block" : "none" }}
                        onLoad={onThumbnailLoad}
                        alt={`${musicTrack.name} album art`} />
                    {!hasThumbnailLoaded &&
                        <Skeleton />}
                </div>
                <div className="info">
                    <div className="track-name">
                        {musicTrack.name}
                    </div>
                    <div className="artist-names">
                        {artists}
                    </div>
                </div>
                <div className="open-links">
                    <IonButton
                        onClick={toggleLinks}
                        className="icon-button"
                        color="medium"
                        fill="clear">
                        <IonIcon icon={showLinks ? chevronUpSharp : chevronDownSharp} />
                    </IonButton>
                </div>
            </div>
            <Collapse isOpened={showLinks}>
                <div className="track-links">
                    {musicServiceLinks.map((musicService) => (
                        <IonButton
                            onClick={musicService.name === "YouTube" ? toggleYouTubeFrame : () => openMusicServiceUrl(musicService.linkUrl!)}
                            className="btn-music-service-link"
                            color="primary"
                            mode="ios"
                            size="small"
                            key={musicService.name}>
                            <IonIcon icon={musicService.imageUrl} />
                            {musicService.name}
                        </IonButton>
                    ))}
                </div>
            </Collapse>
        </div>
    )
}