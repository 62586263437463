import Client, { ApiException, DevotionalElement, YouTubeVideoSearch } from 'grow.client';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import produce from 'immer';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';
import { useAppDispatch } from '../../../../../store/store';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import './YouTubeSettings.scss';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';

interface Props {
    devotionalElement: DevotionalElement
}

export const YouTubeSettings: React.FC<Props> = ({ devotionalElement }) => {
    const [video, setVideo] = useState<YouTubeVideoSearch | null>(null);
    const [url, setUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!url) return;
        let isCancelled = false;

        const getYouTubeVideo = () => {
            const client = new Client();
            client.getVideo(url).then((video) => {
                if (isCancelled) return;
                setVideo(video);
                setErrorMessage("");

                const updatedDevotionalElement = produce(devotionalElement, draft => {
                    if (!draft.youTubeElement) return;
                    draft.youTubeElement.youTubeId = video.id;
                });
                dispatch(setDevotionalElement(updatedDevotionalElement));
            }).catch((error: ApiException) => {
                const errorJson = JSON.parse(error.response);
                setErrorMessage(errorJson);
                setVideo(null);
            }).finally(() => setIsLoading(false))
        }

        setIsLoading(true);
        const timeoutId = setTimeout(() => getYouTubeVideo(), 250);
        return () => {
            isCancelled = true;
            clearTimeout(timeoutId);
        }
    }, [url, devotionalElement]);

    return (
        <div className="youtube-settings">
            <FormControl fullWidth margin="normal">
                <Typography>Enter the web address of the YouTube video</Typography>
                <TextField
                    id="text"
                    onChange={(e) => setUrl(e.target.value)}
                    value={url}
                    variant="standard"
                    autoFocus
                    placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
            <Container>
                {isLoading &&
                    <div className="circular-progress-wrapper">
                        <CircularProgress />
                    </div>}
                {errorMessage && !isLoading &&
                    <div className="error-message">
                        <div>
                            <WarningIcon />
                        </div>
                        <Typography>{errorMessage}</Typography>
                    </div>}
                {video && !isLoading &&
                    <div className="youtube-preview">
                        <img src={video.thumbnail!} alt={`${video.title} thumbnail`} />
                        <Typography variant="h6">{video.title}</Typography>
                        <Divider variant="fullWidth" />
                        <CardHeader
                            avatar={
                                <Avatar src={video.channel?.image!} />
                            }
                            title={video.channel?.name}
                            titleTypographyProps={{ variant: "body1" }}
                        />
                    </div>}
            </Container>

        </div>
    );
}