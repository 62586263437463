import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { GifSearch, ImageSearch } from 'grow.client';
import { ImageSearchGridItem } from './ImageSearchGridItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import './ImageSearchGrid.scss';
import CircularProgress from '@mui/material/CircularProgress';

interface ImageSearchColumn {
    images: (ImageSearch | GifSearch)[]
    total: number
}

interface Props {
    columnCount: 2 | 3
    getImages: (query: string | null | undefined, page: number | undefined) => Promise<(ImageSearch | GifSearch)[]>
    scrollableTargetId: string
    selectImage: (photo: ImageSearch | GifSearch) => void
    title: ReactNode
}

export const ImageSearchGrid: React.FC<Props> = ({ columnCount, getImages, scrollableTargetId, selectImage, title }) => {
    const [query, setQuery] = useState("");
    const [images, setImages] = useState<(ImageSearch | GifSearch)[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const page = useRef(1);

    // Load images on init and query change
    useEffect(() => {
        let isCancelled = false;

        const getPhotos = () => {
            page.current = 1;
            setDisableInfiniteScroll(false);
            getImages(query, page.current).then((newImages) => {
                if (isCancelled) return;
                if (newImages.length < 30) setDisableInfiniteScroll(true);

                setImages(newImages);
            }).finally(() => setIsLoading(false));
        }

        setIsLoading(true);
        const timeoutId = setTimeout(() => getPhotos(), 250);
        return () => {
            isCancelled = true;
            clearTimeout(timeoutId);
        }
    }, [query]);

    const getNextPhotosPage = () => {
        page.current++;

        getImages(query, page.current).then((newimages) => {
            if (newimages.length < 30) setDisableInfiniteScroll(true);
            setImages(images ? [...images, ...newimages] : [...newimages]);
        })
    }

    const columns: ImageSearchColumn[] = [...Array(columnCount)].map(() => ({ images: [], total: 0 }));
    images?.forEach((image) => {
        const smallestColumn = columns.reduce((prev, curr) => prev.total < curr.total ? prev : curr);
        const ratio = image.height / image.width;
        smallestColumn.images.push(image);
        smallestColumn.total += ratio;
    })

    return (
        <div id="image-search-grid">
            {title}
            <TextField
                label="Search"
                fullWidth
                sx={{ mt: 2 }}
                onChange={e => setQuery(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                variant="standard"
            />
            <InfiniteScroll
                dataLength={images?.length ?? 0}
                hasMore={!disableInfiniteScroll}
                next={getNextPhotosPage}
                loader={
                    (<div className="circular-progress-wrapper">
                        <CircularProgress />
                    </div>)
                }
                scrollableTarget={scrollableTargetId}>
                <Container sx={{ mt: 2 }} disableGutters>
                    <Grid container>
                        {[...Array(columnCount)].map((value, index) =>
                            <Grid item xs={12 / columnCount}>
                                {columns[index].images.map((photo) =>
                                    <ImageSearchGridItem image={photo} onClick={selectImage} />)}
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </InfiniteScroll>
        </div>
    )
}