import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Slider from "@mui/material/Slider"
import Typography from "@mui/material/Typography"
import produce from "immer"
import { setCoverImage } from "../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice"
import store, { useAppDispatch, useSelector } from "../../../store/store"

type FilterType = "brightness" | "contrast" | "grayScale" | "saturation";

export const CoverImageBackgroundSettings = () => {
    const coverImage = useSelector(state => state.devotionalBuilderHistory.present.devotional?.coverImage!);
    const dispatch = useAppDispatch();

    const resetFilters = () => {
        const coverImage = store.getState().devotionalBuilderHistory.present.devotional?.coverImage;
        if (!coverImage) return;

        const updatedCoverImage = produce(coverImage, draft => {
            draft.brightness = 0;
            draft.contrast = 0;
            draft.grayScale = 0;
            draft.saturation = 0;
        });
        dispatch(setCoverImage(updatedCoverImage));
    }

    const onChange = (name: FilterType, value: number | number[]) => {
        const coverImage = store.getState().devotionalBuilderHistory.present.devotional?.coverImage;
        if (!coverImage) return;

        const updatedCoverImage = produce(coverImage, draft => {
            draft[name] = Array.isArray(value) ? value[0] : value;
        });
        dispatch(setCoverImage(updatedCoverImage));
    }

    return (
        <div>
            <Button
                onClick={resetFilters}
                color="primary"
                variant="contained"
                fullWidth>
                Reset
            </Button>
            <FormControl fullWidth margin="normal">
                <Typography gutterBottom>
                    Brightness
                </Typography>
                <Slider
                    name="brightness"
                    onChange={(e, value) => onChange("brightness", value)}
                    value={coverImage.brightness}
                    valueLabelDisplay="auto"
                    step={10}
                    min={-100}
                    max={100} />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Typography gutterBottom>
                    Contrast
                </Typography>
                <Slider
                    name="contrast"
                    onChange={(e, value) => onChange("contrast", value)}
                    value={coverImage.contrast}
                    valueLabelDisplay="auto"
                    step={10}
                    min={-100}
                    max={100} />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Typography gutterBottom>
                    Grayscale
                </Typography>
                <Slider
                    name="grayscale"
                    onChange={(e, value) => onChange("grayScale", value)}
                    value={coverImage.grayScale}
                    valueLabelDisplay="auto"
                    step={10}
                    min={0}
                    max={100} />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Typography gutterBottom>
                    Saturation
                </Typography>
                <Slider
                    name="saturation"
                    onChange={(e, value) => onChange("saturation", value)}
                    value={coverImage.saturation}
                    valueLabelDisplay="auto"
                    step={10}
                    min={-100}
                    max={100} />
            </FormControl>
        </div>
    )
}