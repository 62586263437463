import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export const OverviewPage = () => {
    return (
        <Box component="main">
            <Typography variant="h4">
                Overview
            </Typography>
        </Box>
    );
}