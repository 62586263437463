import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Client, { Tag } from 'grow.client';
import Chip from '@mui/material/Chip';

interface Props {
    values: Tag[]
    setValues: (value: Tag[]) => void
}

export const TagSearchInput: React.FC<Props> = ({ values, setValues }) => {
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly Tag[]>([]);
    const defaultOptions = useRef<Tag[] | null>(null);

    useEffect(() => {
        const client = new Client();
        client.searchTags(null, 1).then((results) => {
            defaultOptions.current = results;
            setOptions(results);
        })
    }, [])

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(defaultOptions.current ?? []);
            return undefined;
        }

        const client = new Client();
        client.searchTags(inputValue, 1).then((results) => {
            if (active) {
                const newOptions = results.filter(r => !values.find(v => v.id === r.id));
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [values, inputValue]);

    return (
        <Autocomplete
            multiple
            getOptionLabel={(option) => option.name!}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={values}
            ListboxProps={{
                style: {
                    maxHeight: "200px"
                }
            }}
            onChange={(event: any, newValues: Tag[]) => {
                setOptions(newValues ? [...newValues, ...options] : options);
                setValues(newValues);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search for Tags"
                    variant="standard"
                    fullWidth
                />
            )}
            renderTags={(values: readonly Tag[], getTagProps) =>
                values.map((option: Tag, index: number) => (
                    <Chip variant="filled" label={option.name} {...getTagProps({ index })} />
                ))
            }
        />
    );
}
