import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { UserProfileSearchInput } from '../../components/UserProfileSearchInput/UserProfileSearchInput';
import { PaperAvatar } from "../../components/PaperAvatar/PaperAvatar";
import { rocket } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useState } from "react";
import { ProfileSmall } from "grow.client";
import store, { useAppDispatch, useSelector } from "../../store/store";
import { history } from '../../functions/history';
import { setPublishingProfile } from "../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { setShowPublishMessage } from "../../store/slices/devotionalBuilderSlice/devotionalBuilderSlice";

interface Props {
    publishDevotional: () => void
}

export const PublishTab: React.FC<Props> = ({ publishDevotional }) => {
    const publishingProfileId = useSelector(state => state.devotionalBuilderHistory.present.devotional?.businessProfileId);
    const publishResult = useSelector(state => state.devotionalBuilder.publishResult);
    const showPublishMessage = useSelector(state => state.devotionalBuilder.showPublishMessage);
    const isPublishing = useSelector(state => state.devotionalBuilder.isPublishing);
    const [checkRights, setCheckRights] = useState(false);
    const [checkAppropriate, setCheckAppropriate] = useState(false);
    const [checkHosting, setCheckHosting] = useState(false);
    const dispatch = useAppDispatch();

    const publishingProfile = (publishingProfileId ? store.getState().user.businessProfiles.find(p => p.businessProfile!.id === publishingProfileId)?.businessProfile : store.getState().user.userProfile) ?? null;

    const setDevotionalPublishingProfile = (value: ProfileSmall | null) => {
        const userProfile = store.getState().user.userProfile;
        const profileId = !value || value.id === userProfile?.id ? null : value.id;
        dispatch(setPublishingProfile(profileId))
    }

    return (
        <Container maxWidth="sm" className="paper-panel publish-paper">
            <PaperAvatar color="seagreen">
                <IonIcon icon={rocket} size="large" />
            </PaperAvatar>
            <Paper className="paper-modal" elevation={24}>
                <Typography variant="h5">Publish Plan</Typography>
                <FormControl fullWidth margin="dense">
                    <Typography>
                        Publish as
                    </Typography>
                    <UserProfileSearchInput value={publishingProfile} setValue={setDevotionalPublishingProfile} />
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <FormControlLabel
                        control={(
                            <Checkbox
                                value={checkRights}
                                onChange={e => setCheckRights(e.target.checked)} />
                        )}
                        label="I have the rights to use everything written in this plan."
                    />
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <FormControlLabel
                        control={(
                            <Checkbox
                                value={checkAppropriate}
                                onChange={e => setCheckAppropriate(e.target.checked)} />
                        )}
                        label={<span>All content in this plan is in line with <a href="https://growfaith.co/what-we-believe" target="_blank" rel="noreferrer">these beliefs</a>.</span>}
                    />
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <FormControlLabel
                        control={(
                            <Checkbox
                                value={checkHosting}
                                onChange={e => setCheckHosting(e.target.checked)} />
                        )}
                        label="I give Grow the right to host this plan on their platform."
                    />
                </FormControl>
                <Typography sx={{ marginTop: 2, marginBottom: 3 }}>
                    Once you publish your plan, you will be unable to make any further changes to it. Be sure you are finished before proceeding.
                </Typography>
                <Button
                    onClick={publishDevotional}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disableElevation={true}
                    className="submit lg-button"
                    disabled={!checkRights || !checkAppropriate || !checkHosting || isPublishing}>
                    {!isPublishing ? "Publish" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                </Button>
            </Paper>
            <Dialog
                open={showPublishMessage}
                onClose={() => { if (!publishResult?.success) dispatch(setShowPublishMessage(false)) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {publishResult?.success ? "Successfully Published Plan" : "Failed to Publish Plan"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ whiteSpace: "pre-line" }}>
                        {publishResult?.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!publishResult?.success &&
                        <Button onClick={() => dispatch(setShowPublishMessage(false))}>
                            OK
                        </Button>}
                    {publishResult?.success &&
                        <Button onClick={() => history.push("/home")}>
                            OK
                        </Button>}
                </DialogActions>
            </Dialog>
        </Container>
    );
}