import { DevotionalSharePost, EPostAttachmentType, ImagePost, MusicTrackPost, Post, PostSharePost, WebLinkPost } from 'grow.client';

interface Props {
    post: Post
}

export const PostAttachment: React.FC<Props> = ({ post }) => {
    let imageUrl: string | null | undefined = null;

    if (post.attachmentType === EPostAttachmentType.Post) {
        post = (post as PostSharePost).sharedPost!;
    }

    switch (post.attachmentType) {
        case EPostAttachmentType.Devotional:
            imageUrl = (post as DevotionalSharePost).devotional?.image;
            break;
        case EPostAttachmentType.Gif:
        case EPostAttachmentType.Image:
            imageUrl = (post as ImagePost).imageUrl;
            break;
        case EPostAttachmentType.Music:
            imageUrl = (post as MusicTrackPost).musicTrack?.albumArtUrl;
            break;
        case EPostAttachmentType.WebLink:
            imageUrl = (post as WebLinkPost).webLink?.imageUrl;
            break;
    }

    return (
        <div className="post-attachment">
            {imageUrl &&
                <img src={imageUrl} alt="Post attachment" />}
        </div>
    )
}