import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { MembersTab } from './MembersTab';
import { PendingMembersTab } from './PendingMembersTab';
import { useState } from "react"
import { Add } from "@mui/icons-material";
import React from "react"
import { AddMemberModal } from './AddMemberModal';
import './MembersPage.scss';
import { useSelector } from "../../store/store";
import { selectBusinessMemberByUsername } from "../../store/slices/userSlice/userSlice";
import { useParams } from "react-router";

interface RouterParams {
    username: string
}

export const MembersPage = () => {
    const { username } = useParams<RouterParams>();
    const [tab, setTab] = useState("members");
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const businessMember = useSelector(selectBusinessMemberByUsername(username));

    return (
        <React.Fragment>
            <Box id="members-page" component="main">
                {businessMember?.admin &&
                    <Box className="box">
                        <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                            <Tab label="Members" value="members" />
                            <Tab label="Pending" value="pending" />
                        </Tabs>
                        <Button
                            onClick={() => setShowAddMemberModal(true)}
                            variant="contained"
                            startIcon={<Add />}
                            disableElevation={true}
                            className="add-member-button">
                            Add Member
                        </Button>
                    </Box>}
                <div hidden={tab !== "members"}>
                    <MembersTab />
                </div>
                {businessMember?.admin &&
                    <div hidden={tab !== "pending"}>
                        <PendingMembersTab />
                    </div>}
            </Box>
            <AddMemberModal show={showAddMemberModal} setShow={setShowAddMemberModal} />
        </React.Fragment>
    )
}