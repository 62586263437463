import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from '../../store/store';
import Button from "@mui/material/Button";
import { Add } from '@mui/icons-material';
import Client, { CreateBusinessProfileResource, EBusinessType } from "grow.client";
import { setBusinessProfiles } from "../../store/slices/userSlice/userSlice";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
    show: boolean
    setShow: (show: boolean) => void
}

export const CreateBusinessProfilePage: React.FC<Props> = ({ show, setShow }) => {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [businessType, setBusinessType] = useState<EBusinessType | null>(null);

    const [usernameCheckLoading, setUsernameCheckLoading] = useState(false);
    const [isUsernameValid, setIsUsernameValid] = useState<boolean | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useAppDispatch();

    const requiredFieldsComplete = !!name && !!username && !!businessType && isUsernameValid;

    // Check username is valid on update
    useEffect(() => {
        const checkIsUsernameValid = () => {
            const client = new Client();
            if (username === "") {
                setIsUsernameValid(null);
                return;
            };
            setUsernameCheckLoading(true);
            client.isUsernameValid(username).then((isUsernameValid) => {
                setUsernameCheckLoading(false);
                setIsUsernameValid(isUsernameValid.isValid);
            })
        }

        const timeoutId = setTimeout(() => checkIsUsernameValid(), 500);
        return () => clearTimeout(timeoutId);
    }, [username]);

    const createProfile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!requiredFieldsComplete) return;

        setIsSubmitting(true);

        const client = new Client();

        const createBusinessProfileResource: CreateBusinessProfileResource = {
            name: name,
            username: username,
            businessType: businessType!
        };

        client.createBusinessProfile(createBusinessProfileResource).then((profile) => {
            client.getUserBusinessMembers().then((businessMembers) => {
                dispatch(setBusinessProfiles(businessMembers));
                setIsSubmitting(false);
                setName("");
                setUsername("");
                setBusinessType(null);
                setShow(false);
            });
        }).catch((res) => {
            const errorMessage = JSON.parse(res.response).Message;
            setErrorMessage(errorMessage);
            setIsSubmitting(false);
        });
    }

    let usernameIcon = <div />;
    if (usernameCheckLoading) usernameIcon = <CircularProgress size={24} />;
    else {
        if (isUsernameValid === null) usernameIcon = <div />;
        else if (isUsernameValid) usernameIcon = <CheckCircleOutlineIcon color="success" />;
        else if (!isUsernameValid) usernameIcon = <HighlightOffIcon color="error" />;
    }

    return (
        <Modal
            open={show}
            onClose={() => setShow(false)}>
            <Box className="modal create-business-profile-modal" sx={{ width: 500 }}>
                <form onSubmit={createProfile}>
                    <Typography variant="h6">
                        Create Business Account
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Looking to get your church, band or organisation on Grow?
                    </Typography>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="name"
                            label="Name"
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            variant="standard"
                            autoFocus
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="username"
                            label="Username"
                            required
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            variant="standard"
                            InputProps={{
                                spellCheck: false,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {usernameIcon}
                                    </InputAdornment>
                                )
                            }}
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth margin="normal">
                        <InputLabel id="header-type-label">Type</InputLabel>
                        <Select
                            labelId="header-type-label"
                            required
                            value={businessType}
                            onChange={(e) => setBusinessType(e.target.value as EBusinessType)}
                        >
                            <MenuItem value={EBusinessType.Church}>Church</MenuItem>
                            <MenuItem value={EBusinessType.Charity}>Charity</MenuItem>
                            <MenuItem value={EBusinessType.MusicArtist}>Music Artist</MenuItem>
                            <MenuItem value={EBusinessType.Influencer}>Influencer</MenuItem>
                            <MenuItem value={EBusinessType.Organisation}>Organisation</MenuItem>
                            <MenuItem value={EBusinessType.Other}>Other</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        sx={{ mt: 3, width: 160 }}
                        variant="contained"
                        startIcon={!isSubmitting ? <Add /> : null}
                        disableElevation={true}
                        disabled={isSubmitting}
                        className="submit-new-business-profile-button">
                        {!isSubmitting ? "Create Account" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                    </Button>
                    <Typography variant="body1" color="error" align="center" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                </form>
            </Box>
        </Modal>
    );
}