import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from '../../store/store';
import Button from "@mui/material/Button";
import Client, { ApiException, BusinessProfile, CurrentUserProfile, EBusinessType, Profile, UpdateBusinessProfileResource, UpdateUserProfileResource } from "grow.client";
import { setBusinessProfiles, setUserProfile } from "../../store/slices/userSlice/userSlice";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { isBusinessProfile, isCurrentUserProfile } from "../../typeGuards/profileTypeGuards";
import { uploadProfileImage } from "../../functions/profileImage";
import { ImageFile } from "../../models/ImageFile";
import { selectFiles } from '../../functions/selectFiles';
import './EditProfilePage.scss';
import { history } from '../../functions/history';

interface Props {
    profileUsername: string
    show: boolean
    setShow: (show: boolean) => void
}

export const EditProfilePage: React.FC<Props> = ({ profileUsername, show, setShow }) => {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [bio, setBio] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [location, setLocation] = useState("");
    const [businessType, setBusinessType] = useState<EBusinessType>();

    const [profile, setProfile] = useState<BusinessProfile | CurrentUserProfile | null>(null);
    const [isUsernameValid, setIsUsernameValid] = useState<boolean | null>(null);
    const [usernameCheckLoading, setUsernameCheckLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [temporaryProfileImage, setTemporaryProfileImage] = useState<ImageFile | null>(null)

    const dispatch = useAppDispatch();

    const profileImageUpdated = !!temporaryProfileImage;
    const formUpdated = name !== profile?.name ||
        (username !== profile.username && isUsernameValid) ||
        bio !== profile.bio ||
        websiteUrl !== profile.websiteUrl ||
        location !== profile.location ||
        (isBusinessProfile(profile) && businessType !== profile.businessType);

    // Load profile on init
    useEffect(() => {
        if (!profileUsername || !show) return;

        const client = new Client();
        client.getProfile(profileUsername, null).then((profile) => {
            isCurrentUserProfile(profile) ?
                setProfile(profile as CurrentUserProfile) :
                setProfile(profile as BusinessProfile);

            setName(profile.name!);
            setUsername(profile.username!);
            setBio(profile.bio!);
            setWebsiteUrl(profile.websiteUrl!);
            setLocation(profile.location!);
            setTemporaryProfileImage(null);

            if (isBusinessProfile(profile)) {
                setBusinessType(profile.businessType)
            }
        })
    }, [profileUsername, show])

    // Check username is valid on update
    useEffect(() => {
        const checkIsUsernameValid = () => {
            const client = new Client();
            if (username === "" || username === profile?.username) {
                setIsUsernameValid(null);
                return;
            };
            setUsernameCheckLoading(true);
            client.isUsernameValid(username).then((isUsernameValid) => {
                setUsernameCheckLoading(false);
                setIsUsernameValid(isUsernameValid.isValid);
            })
        }

        const timeoutId = setTimeout(() => checkIsUsernameValid(), 500);
        return () => clearTimeout(timeoutId);
    }, [username, profile]);

    const saveChanges = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!profile) return;

        setIsSubmitting(true);

        const client = new Client();

        // Submit new profile image
        if (profileImageUpdated && temporaryProfileImage) {
            try {
                await uploadProfileImage(profile, temporaryProfileImage.file);
            } catch (error) {
                if (!(error instanceof ApiException)) return;
                const errorJson = JSON.parse(error.response);
                setErrorMessage(errorJson.Message);
                setIsSubmitting(false)
                return;
            }
        }

        // Submit form changes
        if (formUpdated) {
            let formPromise: Promise<Profile>;

            if (isCurrentUserProfile(profile)) {
                const updateProfileResource: UpdateUserProfileResource = {
                    name, username, bio, websiteUrl, location,
                    dateOfBirth: profile.dateOfBirth,
                    emailAddress: profile.emailAddress!
                };

                formPromise = client.updateUserProfile(updateProfileResource);
            } else {
                if (!businessType) return;
                const updateProfileResource: UpdateBusinessProfileResource = {
                    name, username, bio, websiteUrl, location, businessType, latitude: null, longitude: null
                };

                formPromise = client.updateBusinessProfile(profile.id, updateProfileResource);
            }

            try {
                await formPromise
            } catch (error) {
                if (!(error instanceof ApiException)) return;

                const errorJson = JSON.parse(error.response);
                setErrorMessage(errorJson.Message);
                setIsSubmitting(false)
                return;
            } finally {
                if (history.location.pathname.startsWith(`/${profile.username}/`)) {
                    const newUrl = history.location.pathname.replace(`/${profile.username}/`, `/${username}/`)
                    history.replace(newUrl);
                }
            }
        }

        setIsSubmitting(false);
        setShow(false);

        if (isBusinessProfile(profile)) {
            client.getUserBusinessMembers().then((businessMembers) => {
                dispatch(setBusinessProfiles(businessMembers));
            })
        } else {
            client.getCurrentUserProfile().then((user) => {
                dispatch(setUserProfile(user));
            });
        }
    }

    const getPhoto = async () => {
        selectFiles({ accept: 'image/*', multiple: false }).then(files => {
            if (files) {
                const imageFile: ImageFile = {
                    file: files[0],
                    url: URL.createObjectURL(files[0])
                };
                setTemporaryProfileImage(imageFile);
            } else {
                setTemporaryProfileImage(null);
            }
        });
    }

    let usernameIcon = <div />;
    if (usernameCheckLoading) usernameIcon = <CircularProgress size={24} />;
    else {
        if (isUsernameValid === null) usernameIcon = <div />;
        else if (isUsernameValid) usernameIcon = <CheckCircleOutlineIcon color="success" />;
        else if (!isUsernameValid) usernameIcon = <HighlightOffIcon color="error" />;
    }

    return (
        <Modal
            open={show}
            onClose={() => setShow(false)}
            keepMounted={false}>
            <Box className="modal edit-profile-modal" sx={{ width: 500 }}>
                <form onSubmit={saveChanges}>
                    <Typography variant="h6">
                        Edit Profile
                    </Typography>
                    <div className="profile-avatar">
                        {profile &&
                            <Avatar
                                onClick={getPhoto}
                                src={temporaryProfileImage ? temporaryProfileImage.url : profile.image ? profile.image : "/assets/images/default-profile-image.jpg"}
                                alt="" />}
                    </div>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="name"
                            label="Name"
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            variant="standard"
                            autoFocus
                            inputProps={{
                                maxLength: 100
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="username"
                            label="Username"
                            required
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            variant="standard"
                            InputProps={{
                                spellCheck: false,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {usernameIcon}
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{
                                maxLength: 20
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="bio"
                            label="Bio"
                            onChange={(e) => setBio(e.target.value)}
                            value={bio}
                            rows={3}
                            autoCapitalize="sentences"
                            variant="standard"
                            multiline
                            maxRows={2}
                            InputProps={{
                                spellCheck: true,
                            }}
                            inputProps={{
                                maxLength: 200
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="website"
                            label="Website"
                            onChange={(e) => setWebsiteUrl(e.target.value)}
                            value={websiteUrl}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="location"
                            label="Location"
                            onChange={(e) => setLocation(e.target.value)}
                            value={location}
                            autoCapitalize="words"
                            variant="standard"
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                    </FormControl>
                    {businessType &&
                        <FormControl variant="standard" fullWidth margin="normal">
                            <InputLabel id="header-type-label">Type</InputLabel>
                            <Select
                                labelId="header-type-label"
                                required
                                value={businessType}
                                onChange={(e) => setBusinessType(e.target.value as EBusinessType)}
                            >
                                <MenuItem value={EBusinessType.Church}>Church</MenuItem>
                                <MenuItem value={EBusinessType.Charity}>Charity</MenuItem>
                                <MenuItem value={EBusinessType.MusicArtist}>Music Artist</MenuItem>
                                <MenuItem value={EBusinessType.Influencer}>Influencer</MenuItem>
                                <MenuItem value={EBusinessType.Organisation}>Organisation</MenuItem>
                                <MenuItem value={EBusinessType.Other}>Other</MenuItem>
                            </Select>
                        </FormControl>}
                    <Button
                        type="submit"
                        sx={{ mt: 3, width: 160 }}
                        variant="contained"
                        disableElevation={true}
                        disabled={isSubmitting || (!formUpdated && !profileImageUpdated)}
                        className="submit-edit-profile-button">
                        {!isSubmitting ? "Save" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                    </Button>
                    <Typography variant="body1" color="error" align="center" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                </form>
            </Box>
        </Modal>
    );
}