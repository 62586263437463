import TextFieldsIcon from '@mui/icons-material/TextFields';
import Paper from '@mui/material/Paper';
import { DevotionalCoverImageTextLayer, EDevotionalCoverImageLayerType, EDevotionalCoverImageTextLayerAlignment } from 'grow.client';
import { addCoverImageLayer } from '../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { useAppDispatch, useSelector } from '../../../store/store';
import './CoverImageToolBox.scss';

export const CoverImageToolBox = () => {
    const backgroundUrl = useSelector(state => state.devotionalBuilderHistory.present.devotional?.coverImage?.backgroundImageUrl);
    const dispatch = useAppDispatch();

    const addTextLayer = () => {
        const textLayer: DevotionalCoverImageTextLayer = {
            align: EDevotionalCoverImageTextLayerAlignment.Left,
            color: !backgroundUrl ? "#000" : "#fff",
            fontId: 63,
            fontSize: 12,
            text: "",
            type: EDevotionalCoverImageLayerType.Text,
            x: 20,
            y: 20,
            discriminator: "DevotionalCoverImageTextLayer"
        };

        dispatch(addCoverImageLayer(textLayer));
    }

    return (
        <div className="cover-image-tool-box">
            <Paper
                className="cover-image-tool-box-item"
                elevation={2}
                onClick={addTextLayer}>
                <TextFieldsIcon />
                <div>Text</div>
            </Paper>
        </div>
    )
}