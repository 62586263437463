import Paper from "@mui/material/Paper";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stage, Image, Label, Layer, Tag, Text } from 'react-konva';
import useImage from "use-image";
import { setCoverImageBackgroundPosition, setCoverImageLayerPosition, setCoverImageSelectedLayer } from "../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice";
import store, { useAppDispatch, useSelector } from "../../../store/store";
import { isCoverImageTextLayer } from '../../../typeGuards/CoverImageLayerTypeGuard';
import './CoverImageCanvas.scss';

interface Props {
    coverImageStageRef: React.RefObject<Konva.Stage>
}

export const CoverImageCanvas: React.FC<Props> = ({ coverImageStageRef }) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const fonts = useSelector(state => state.devotionalBuilder.fonts);
    const fontsLoaded = useSelector(state => state.devotionalBuilder.fontsLoaded);
    const step = useSelector(state => state.devotionalBuilderHistory.present.step);
    const coverImage = useSelector(state => state.devotionalBuilderHistory.present.devotional?.coverImage);
    const [backgroundImage, backgroundStatus] = useImage(coverImage?.backgroundImageUrl ?? "", "anonymous");
    const backgroundRef = useRef<Konva.Image>(null);
    const dispatch = useAppDispatch();

    const containerRef = useCallback((node: HTMLDivElement) => {
        if (step !== 1) return;

        if (node) {
            setContainerWidth(node.offsetWidth);
        }

        window.addEventListener("resize", () => {
            if (node)
                setContainerWidth(node.offsetWidth);
        });
    }, [step]);

    const changeSelectedLayer = (layerIndex: number) => {
        const selectedLayer = store.getState().devotionalBuilderHistory.present.coverImageSelectedLayer;
        const layer = selectedLayer !== layerIndex ? layerIndex : null;
        dispatch(setCoverImageSelectedLayer(layer));
    }

    const handleLayerDrag = (e: KonvaEventObject<DragEvent>, index: number) => {
        let x = e.target.x();
        let y = e.target.y();

        const widthBoundary = width - e.target.width();
        const heightBoundary = height - e.target.height();

        x = x < 0 ? 0 : x > widthBoundary ? widthBoundary : x;
        y = y < 0 ? 0 : y > heightBoundary ? heightBoundary : y;

        e.target.x(x);
        e.target.y(y);

        const position = {
            index, x, y
        }

        dispatch(setCoverImageLayerPosition(position));
        dispatch(setCoverImageSelectedLayer(index));
    };

    const handleBackgroundDrag = (e: KonvaEventObject<DragEvent>) => {
        let y = e.target.y();
        const heightBoundary = height - e.target.height();

        y = y > 0 ? 0 : y < heightBoundary ? heightBoundary : y;
        e.target.y(y);
        e.target.x(0);

        dispatch(setCoverImageBackgroundPosition(y));
    };

    const changeCursor = (cursor: string) => {
        if (!coverImageStageRef.current) return;
        const stage = coverImageStageRef.current.container();
        stage.style.cursor = cursor;
    }

    const height = 1080;
    const width = 1920;
    const scale = containerWidth / width;

    const selectedLayer = store.getState().devotionalBuilderHistory.present.coverImageSelectedLayer;
    const textLayers = coverImage?.layers!.filter(isCoverImageTextLayer);

    if (!fontsLoaded) return null;

    if (backgroundRef.current && coverImage) {
        const canvas = backgroundRef.current.getCanvas()._canvas;
        canvas.style.filter = `brightness(${coverImage.brightness + 100}%) contrast(${coverImage.contrast + 100}%) grayscale(${coverImage.grayScale}%) saturate(${coverImage.saturation + 100}%)`;
    }

    return (
        <div className="cover-image-designer-container" ref={containerRef}>
            <Paper elevation={10}>
                {coverImage?.backgroundImageUrl && backgroundStatus === "loading" &&
                    <div className="canvas-loading">
                        <img src="/assets/images/loading.gif" alt="Loading" />
                    </div>}
                <Stage
                    className="stage-container"
                    height={height * scale}
                    width={width * scale}
                    scaleX={scale}
                    scaleY={scale}
                    onMouseEnter={() => changeCursor("ns-resize")}
                    ref={coverImageStageRef}>
                    <Layer>
                        {coverImage?.backgroundImageUrl &&
                            <Image
                                ref={backgroundRef}
                                draggable
                                onDragMove={handleBackgroundDrag}
                                image={backgroundImage}
                                x={0}
                                y={coverImage.y}
                            />}
                    </Layer>
                    {textLayers && textLayers.map((textLayer, index) =>
                        <Layer key={index}>
                            <Label
                                draggable
                                onClick={(e) => changeSelectedLayer(index)}
                                onDragMove={(e) => { handleLayerDrag(e, index) }}
                                onMouseEnter={() => changeCursor("move")}
                                onMouseLeave={() => changeCursor("ns-resize")}
                                x={textLayer.x}
                                y={textLayer.y}>
                                {selectedLayer === index &&
                                    <Tag
                                        stroke="#fff"
                                        strokeWidth={5} />}
                                <Text
                                    align={textLayer.align.toLowerCase()}
                                    fill={textLayer.color!}
                                    fontFamily={fonts?.find(f => f.id === textLayer.fontId)?.family ?? "Roboto"}
                                    fontSize={textLayer.fontSize * 8}
                                    padding={10}
                                    text={!textLayer.text ? "Text Layer" : textLayer.text} />
                            </Label>
                        </Layer>)}
                </Stage>
            </Paper>
        </div>
    )
}