import { DevotionalElement } from 'grow.client';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import produce from 'immer';
import { useAppDispatch } from '../../../../../store/store';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';

interface Props {
    devotionalElement: DevotionalElement
}

export const ParagraphSettings: React.FC<Props> = ({ devotionalElement }) => {
    const dispatch = useAppDispatch();
    const paragraphElement = devotionalElement.paragraphElement;

    const setText = (value: string) => {
        const updatedDevotionalElement = produce(devotionalElement, draft => {
            if (!draft.paragraphElement) return;
            draft.paragraphElement.text = value;
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));
    }

    return (
        <div>
            <FormControl fullWidth margin="normal">
                <TextField
                    id="text"
                    label="Text"
                    onChange={(e) => setText(e.target.value)}
                    value={paragraphElement?.text}
                    variant="standard"
                    autoFocus
                    multiline
                    rows={14}
                    inputProps={{
                        spellCheck: true
                    }}
                />
            </FormControl>
        </div>
    );
}