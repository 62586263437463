import { DevotionalElement, EHeaderElementType, EListElementType } from 'grow.client';
import FormControl from '@mui/material/FormControl';
import produce from 'immer';
import { useAppDispatch } from '../../../../../store/store';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
    devotionalElement: DevotionalElement
}

export const ListSettings: React.FC<Props> = ({ devotionalElement }) => {
    const dispatch = useAppDispatch();
    const listElement = devotionalElement.listElement;

    const setType = (value: string) => {
        const updatedDevotionalElement = produce(devotionalElement, draft => {
            if (!draft.listElement) return;
            draft.listElement.type = value as EListElementType;
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));
    }

    const setText = (value: string) => {
        const updatedDevotionalElement = produce(devotionalElement, draft => {
            if (!draft.listElement) return;
            draft.listElement.text = value;
        });
        dispatch(setDevotionalElement(updatedDevotionalElement));
    }

    return (
        <div>
            <FormControl variant="standard" fullWidth margin="normal">
                <InputLabel id="list-type-label">Type</InputLabel>
                <Select
                    labelId="list-type-label"
                    value={listElement?.type ?? undefined}
                    onChange={(e) => setType(e.target.value)}>
                    <MenuItem value={EListElementType.Bullets}>Bullets</MenuItem>
                    <MenuItem value={EListElementType.Numbered}>Numbered</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    id="items"
                    label="Items"
                    onChange={(e) => setText(e.target.value)}
                    value={listElement?.text}
                    variant="standard"
                    multiline
                    rows={8}
                    inputProps={{
                        spellCheck: true
                    }}
                />
            </FormControl>
            <Typography variant="caption">
                Please write each of your list items separated by a new line.
            </Typography>
            <br />
            <Typography variant="caption">
                Insert '--' before text you want to be on a new line within the same list item.
            </Typography>
        </div>
    );
}