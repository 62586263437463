import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DevotionalDay, EDevotionalElementType } from 'grow.client';
import { addDevotionalDay, deleteDevotionalDay, copyDevotionalDay, setSelectedDevotionalDayId } from '../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import store, { useAppDispatch, useSelector } from '../../../store/store';
import { Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import ContentCopy from '@mui/icons-material/ContentCopy';

interface Props {
    devotionalDay: DevotionalDay
    index: number
}

export const DailyContentDaySelectorItem: React.FC<Props> = ({ devotionalDay, index }) => {
    const selectedDevotionalDayId = useSelector(state => state.devotionalBuilderHistory.present.selectedDevotionalDayId);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(setSelectedDevotionalDayId(devotionalDay.id));
    }

    const warnDeleteDay = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (devotionalDay.devotionalElements!.length > 0)
            setShowDeleteWarning(true);
        else
            deleteDay();
    }

    const deleteDay = () => {
        let state = store.getState();
        setShowDeleteWarning(false);
        dispatch(deleteDevotionalDay({ index }));

        if (state.devotionalBuilderHistory.present.selectedDevotionalDayId === devotionalDay.id) {
            const dayToMoveTo = state.devotionalBuilderHistory.present.devotional?.devotionalDays![index - 1] ?? state.devotionalBuilderHistory.present.devotional?.devotionalDays![index + 1];
            if (dayToMoveTo)
                dispatch(setSelectedDevotionalDayId(dayToMoveTo.id));
            else {
                dispatch(addDevotionalDay());
                state = store.getState();
                const newDay = state.devotionalBuilderHistory.present.devotional?.devotionalDays![0];
                if (newDay) dispatch(setSelectedDevotionalDayId(newDay.id));
            }
        }
    }

    const copyDay = () => {
        dispatch(copyDevotionalDay(devotionalDay));
    }

    const getStyle = (isDragging: boolean, style: DraggingStyle | NotDraggingStyle | undefined) => ({
        background: selectedDevotionalDayId === devotionalDay.id ? "#eee" : "#fff",
        ...style
    })

    let text = "";
    const textElements = devotionalDay.devotionalElements!.filter((e) => e.elementType === EDevotionalElementType.Title || e.elementType === EDevotionalElementType.Paragraph);
    if (textElements?.length > 0) {
        const firstTextElement = textElements[0];
        text = (firstTextElement.paragraphElement?.text ?? firstTextElement.titleElement?.text) ?? "";
    }

    if (text.length > 60) {
        text = text.substring(0, 57) + "...";
    }

    return (
        <React.Fragment>
            <Draggable key={devotionalDay.id} draggableId={devotionalDay.id.toString()} index={index}>
                {(provided, snapshot) => (
                    <div
                        className="day-selector-item"
                        onClick={onClick}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                    >
                        <DragHandleIcon />
                        <span className="day-label">Day {index + 1}</span>
                        <span className="text-label"> {text}</span>
                        <IconButton
                            className="copy-day-button"
                            size="small"
                            onClick={copyDay}>
                            <ContentCopy />
                        </IconButton>
                        <IconButton
                            className="delete-day-button"
                            onClick={warnDeleteDay}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                )}
            </Draggable>
            <Dialog
                open={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
            >
                <DialogTitle>
                    Delete devotional day?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete devotional day {index + 1}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteWarning(false)}>Cancel</Button>
                    <Button onClick={deleteDay}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}