import { IonSelect, IonSelectOption, IonText } from '@ionic/react';
import Client, { DevotionalElement, EVerseType } from 'grow.client';
import produce from 'immer';
import * as React from 'react';
import { setDevotionalElement } from '../../../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { useAppDispatch, useSelector } from '../../../../../store/store';
import './BibleElement.scss';

interface Props {
    devotionalElement: DevotionalElement
}

export const BibleElement: React.FC<Props> = ({ devotionalElement }) => {
    const scripture = devotionalElement.bibleElement!;
    const verses = scripture.verses!;
    const passage = scripture.passage;
    const translationCode = scripture.translationCode;
    const selected = React.useRef(false);
    const bibleTranslations = useSelector(state => state.devotionalBuilder.bibleTranslations);
    const dispatch = useAppDispatch();

    const changeBibleTranslation = (newTranslationCode: string) => {
        if (newTranslationCode === scripture.translationCode) return;
        if (!selected.current) return;

        selected.current = false;
        
        const client = new Client();
        client.getScripture(scripture.book, scripture.chapter, scripture.firstVerse, scripture.lastVerse, newTranslationCode).then((scripture) => {
            const updatedDevotionalElement = produce(devotionalElement, draft => {
                draft.bibleElement = scripture;
            });
            dispatch(setDevotionalElement(updatedDevotionalElement));
        });
    }

    return (
        <div className="bible-element">
            <div className="bible-element-header">
                <div className="passage">
                    <IonText>{passage}</IonText>
                </div>
                <div className="translation-selector">
                    <IonSelect
                        value={translationCode}
                        onIonBlur={() => selected.current = true}
                        onIonCancel={() => selected.current = false}
                        onIonChange={e => changeBibleTranslation(e.detail.value)}
                        onClick={e => e.stopPropagation()}
                        selectedText={translationCode}
                        interface={"action-sheet"} >
                        {bibleTranslations && bibleTranslations.map((b) =>
                            <IonSelectOption value={b.code} key={b.code}>{b.name} ({b.code})</IonSelectOption>)}
                    </IonSelect>
                </div>
            </div>
            <div className="verses">
                <p>
                    {verses.map((v, index) => {
                        if (v.type === EVerseType.Verse)
                            return (
                                <React.Fragment key={index}>
                                    <span className="verse-number">{v.displayFirstNumber}</span>
                                    {v.text}
                                </React.Fragment>
                            )
                        else if (v.type === EVerseType.Header)
                            return <h4 key={index}>{v.text}</h4>

                        else if (v.type === EVerseType.LineBreak)
                            return <br key={index} />
                        else return null;
                    })}
                </p>
            </div>
        </div>
    )
}