import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { PaperAvatar } from "../../components/PaperAvatar/PaperAvatar";
import { TagSearchInput } from "../../components/TagSearchInput/TagSearchInput";
import { setDevotionalDescription, setDevotionalTags, setDevotionalTitle } from '../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { useAppDispatch, useSelector } from '../../store/store';
import { Tag } from 'grow.client';

export const PlanInformationTab = () => {
    const devotional = useSelector(state => state.devotionalBuilderHistory.present.devotional);
    const dispatch = useAppDispatch();

    const setDescription = (description: string) => {
        dispatch(setDevotionalDescription(description));
    }

    const setTitle = (title: string) => {
        dispatch(setDevotionalTitle(title));
    }

    const setTags = (tags: Tag[]) => {
        dispatch(setDevotionalTags(tags));
    }

    return (
        <Container maxWidth="sm" className="paper-panel plan-information-paper">
            <PaperAvatar color="seagreen">
                <CreateIcon />
            </PaperAvatar>
            <Paper className="paper-modal" elevation={24}>
                <Typography variant="h5">Plan Information</Typography>
                <FormControl fullWidth margin="dense">
                    <TextField
                        id="title"
                        name="title"
                        label="Title"
                        value={devotional?.name ?? ""}
                        onChange={(e) => setTitle(e.target.value)}
                        margin="dense"
                        autoFocus
                        variant="standard"
                        inputProps={{
                            spellCheck: true
                        }}
                    />
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <TextField
                        id="description"
                        name="description"
                        label="Description"
                        value={devotional?.description ?? ""}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        rows={3}
                        margin="dense"
                        variant="standard"
                        inputProps={{
                            spellCheck: true,
                            maxLength: 500
                        }}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TagSearchInput values={devotional?.tags!} setValues={setTags} />
                </FormControl>
            </Paper>
        </Container>
    );
}