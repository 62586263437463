import AppBar from "@mui/material/AppBar";
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Link } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import Toolbar from "@mui/material/Toolbar";
import { history } from '../../functions/history';
import { useState } from "react";
import { useAppDispatch, useSelector } from "../../store/store";
import { GrowAvatar } from "../GrowAvatar/GrowAvatar";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Client from "grow.client";
import { logOutUser } from "../../store/slices/userSlice/userSlice";
import { EditProfilePage } from '../../pages/EditProfilePage/EditProfilePage';
import './Header.scss';

export const Header = () => {
    const user = useSelector(state => state.user.userProfile);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [showEditProfile, setShowEditProfile] = useState(false);
    const dispatch = useAppDispatch();

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const logout = () => {
        const client = new Client();
        client.logOut().finally(async () => {
            setAnchorEl(null);
            history.push("/");
            localStorage.removeItem("accessTokenExpiry");
            dispatch(logOutUser());
        });
    }

    const editProfile = () => {
        setShowEditProfile(true);
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return (
        <AppBar
            position="sticky"
            color="inherit"
            className="header"
            elevation={0}>
            <Toolbar>
                <div className="toolbar-contents">
                    <Link to="/">
                        <img
                            src="/assets/images/grow-studio-161616.svg"
                            alt="Grow Studio logo"
                            className="logo-image" />
                    </Link>
                    {user &&
                        <GrowAvatar
                            profile={user}
                            onClick={handleClick} />}
                </div>
            </Toolbar>
            <ClickAwayListener onClickAway={e => setAnchorEl(null)}>
                <Popper
                    id="header-popper"
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                >
                    <Box className="header-popper-box">
                        <List className="header-popper-list" disablePadding>
                            <ListItem className="header-popper-list" disablePadding>
                                <ListItemButton onClick={editProfile}>
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit Profile" />
                                </ListItemButton>
                                <ListItemButton onClick={logout}>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Log Out" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Popper>
            </ClickAwayListener>
            {user &&
                <EditProfilePage
                    profileUsername={user.username!}
                    setShow={setShowEditProfile}
                    show={showEditProfile} />}
        </AppBar>
    );
}