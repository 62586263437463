import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { BusinessMember } from "grow.client";
import { history } from "../../functions/history";
import React from "react";
import { GrowAvatar } from '../GrowAvatar/GrowAvatar';
import './BusinessMemberCard.scss';

interface Props {
    businessMember: BusinessMember
}

export const BusinessMemberCard: React.FC<Props> = ({ businessMember }) => {
    const businessProfile = businessMember.businessProfile;

    const onClick = () => history.push(`/${businessProfile!.username}/devotionals`);

    return (
        <Card className="business-member-card" onClick={onClick}>
            <CardContent>
                <div className="profile-card">
                    <GrowAvatar profile={businessProfile} />
                    <div>
                        <Typography variant="h6">
                            {businessProfile?.name}
                        </Typography>
                        <Typography variant="body2">
                            @{businessProfile?.username}
                        </Typography>
                    </div>
                </div>
                <Typography className="bio">
                    {businessProfile?.bio}
                </Typography>
                <Chip label={businessProfile?.businessType} />
            </CardContent>
        </Card>
    );
}