import { DailyContentCanvas } from '../../components/DevotionalBuilder/DailyContentCanvas/DailyContentCanvas';
import { DailyContentDaySelector } from '../../components/DevotionalBuilder/DailyContentDaySelector/DailyContentDaySelector';
import { DailyContentToolBox } from '../../components/DevotionalBuilder/DailyContentToolBox/DailyContentToolBox';
import { useSelector } from '../../store/store';
import './DailyContentTab.scss';

export const DailyContentTab = () => {
    const devotionalDays = useSelector(state => state.devotionalBuilderHistory.present.devotional?.devotionalDays);
    const selectedDevotionalDayId = useSelector(state => state.devotionalBuilderHistory.present.selectedDevotionalDayId);

    return (
        <div>
            <div className="daily-content-tab tab-padding-top">
                <div className="side-panel">
                    <DailyContentDaySelector />
                </div>
                <div className="main-panel">
                    {devotionalDays && devotionalDays.map((devotionalDay) =>
                        <div hidden={selectedDevotionalDayId !== devotionalDay.id} key={devotionalDay.id}>
                            <DailyContentCanvas devotionalDay={devotionalDay} />
                        </div>
                    )}
                </div>
                <div className="side-panel">
                    <DailyContentToolBox />
                </div>
            </div>
        </div>
    )
}