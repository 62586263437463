import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import React from "react"

export const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            <Link color="inherit" href="https://growfaith.co/">
                Grow Faith Co.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}