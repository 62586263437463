import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessMember, BusinessMemberApproval } from 'grow.client';

export interface SnackbarOptions {
    message: string
    color?: AlertColor
}

export interface sliceState {
    members: BusinessMember[] | null
    pendingMembers: BusinessMemberApproval[] | null
}

const initialState: sliceState = {
    members: null,
    pendingMembers: null
};

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        addPendingMember: (state, action: PayloadAction<BusinessMemberApproval>) => {
            state.pendingMembers = state.pendingMembers ? [...state.pendingMembers, action.payload] : [action.payload];
        },
        setMembers: (state, action: PayloadAction<BusinessMember[] | null>) => {
            state.members = action.payload;
        },
        setPendingMembers: (state, action: PayloadAction<BusinessMemberApproval[] | null>) => {
            state.pendingMembers = action.payload;
        }
    }
});

// Actions
export const { addPendingMember, setMembers, setPendingMembers } = memberSlice.actions;

export default memberSlice.reducer;