import { EHeaderElementType } from 'grow.client';
import * as React from 'react';
import './HeaderElement.scss';

interface Props {
    type: EHeaderElementType
}

export const HeaderElement: React.FC<Props> = ({ type }) => {
    let typeText: string;

    switch (type) {
        case EHeaderElementType.Bible:
            typeText = "Bible"
            break;
        case EHeaderElementType.Challenge:
            typeText = "Challenge"
            break;
        case EHeaderElementType.Declaration:
            typeText = "Declaration"
            break;
        case EHeaderElementType.Message:
            typeText = "Message"
            break;
        case EHeaderElementType.Music:
            typeText = "Music"
            break;
        case EHeaderElementType.Prayer:
            typeText = "Prayer"
            break;
        case EHeaderElementType.Reflection:
            typeText = "Reflection"
            break;
    }

    return (
        <div className="header-element">
            <h3>
                {typeText}
            </h3>
        </div>
    )
}