import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { GrowAvatar } from '../GrowAvatar/GrowAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Client, { BusinessMemberApproval } from 'grow.client';
import { useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { setBusinessProfiles } from '../../store/slices/userSlice/userSlice';


interface Props {
    businessMemberApproval: BusinessMemberApproval
    onAction: (id: number) => void
}

export const TeamInvitePanel: React.FC<Props> = ({ businessMemberApproval, onAction }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useAppDispatch();

    const approve = () => {
        if (!businessMemberApproval) return;
        setIsSubmitting(true);

        const client = new Client();
        client.approveBusinessMemberApproval(businessMemberApproval.id).then(() => {
            setIsSubmitting(false);

            client.getUserBusinessMembers().then((businessMembers) => {
                dispatch(setBusinessProfiles(businessMembers));
            });

            onAction(businessMemberApproval.id);
        });
    }

    const decline = () => {
        if (!businessMemberApproval) return;
        setIsSubmitting(true);

        const client = new Client();
        client.declineBusinessMemberApproval(businessMemberApproval.id).then(() => {
            setIsSubmitting(false);
            onAction(businessMemberApproval.id);
        });
    }

    const roles = [];
    if (businessMemberApproval) {
        if (businessMemberApproval.admin) {
            roles.push("Admin")
        } else {
            if (businessMemberApproval.devotionalCreator) roles.push("Devotional Creator")
            else if (businessMemberApproval.devotionalCreatorWithReview) roles.push("Devotional Creator w/ Review")
            if (businessMemberApproval.proofReader) roles.push("Proof Reader")
            if (businessMemberApproval.postCreator) roles.push("Post Creator")
        }
    }

    return (
        <TableRow key={businessMemberApproval.id}>
            <TableCell>
                <ListItem sx={{ padding: 0 }}>
                    <ListItemAvatar>
                        <GrowAvatar profile={businessMemberApproval.businessProfile} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={businessMemberApproval.businessProfile!.name}
                        secondary={`@${businessMemberApproval.businessProfile!.username}`} />
                </ListItem>
            </TableCell>
            <TableCell>
                <ul>
                    {roles.map(role => <li>{role}</li>)}
                </ul>
            </TableCell>
            <TableCell>
                <ListItem sx={{ padding: 0 }}>
                    <ListItemAvatar>
                        <GrowAvatar profile={businessMemberApproval.createdByUserProfile} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={businessMemberApproval.createdByUserProfile!.name}
                        secondary={`@${businessMemberApproval.createdByUserProfile!.username}`} />
                </ListItem>
            </TableCell>
            {!isSubmitting ?
                <TableCell width="200px">
                    <Button
                        onClick={approve}
                        disableElevation={true}
                        sx={{ marginRight: 1 }}
                        variant="contained">
                        Accept
                    </Button>
                    <Button
                        onClick={decline}
                        color="info"
                        disableElevation={true}
                        variant="contained">
                        Decline
                    </Button>
                </TableCell> :
                <TableCell width="200px">
                    <CircularProgress />
                </TableCell>
            }
        </TableRow >
    )
}