import Typography from "@mui/material/Typography";
import Client, { ApiException } from "grow.client";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router";
import { selectProfileByUsername } from "../../store/slices/userSlice/userSlice";
import store, { useAppDispatch, useSelector } from '../../store/store';
import { MembersTable } from './MembersTable';
import { BusinessMemberPermission } from '../../types/BusinessMemberPermission';
import { setMembers } from "../../store/slices/memberSlice/memberSlice";
import produce from "immer";

interface RouterParams {
    username: string
}

export const MembersTab: React.FC = () => {
    const { username } = useParams<RouterParams>();
    const [disableMembersInfiniteScroll, setDisableMembersInfiniteScroll] = useState(false);
    const members = useSelector(state => state.member.members);
    const membersCursor = useRef<number | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setMembers(null));
        getMembersPage();
    }, [username])

    const getMembersPage = () => {
        const profile = selectProfileByUsername(username)(store.getState());
        if (!profile) return;

        const client = new Client();
        return client.getBusinessBusinessMembers(profile.id, membersCursor.current).then((newMembers) => {
            const combinedMembers = [...(store.getState().member.members ?? []), ...newMembers.rows!];
            dispatch(setMembers(combinedMembers));
            membersCursor.current = newMembers.cursor;

            if (!newMembers.hasNextPage) {
                setDisableMembersInfiniteScroll(true);
            }
            return combinedMembers;
        })
    }

    const updatePermissionState = (businessMemberId: number, permission: BusinessMemberPermission, value: boolean) => {
        const newArr = [...members!];
        const index = newArr.findIndex(i => i.id === businessMemberId);
        const updatedMembers = produce(newArr, draft => {
            const item = draft[index];
            if (!item) return;
            item[permission] = value;
        });
        
        dispatch(setMembers(updatedMembers));
        return updatedMembers[index];
    }

    const changePermission = (businessMemberId: number, permission: BusinessMemberPermission, value: boolean) => {
        const item = updatePermissionState(businessMemberId, permission, value);
        if (!item) return;

        const client = new Client();
        client.updateBusinessMember(businessMemberId, item).catch((error: ApiException) => {
            const errorJson = JSON.parse(error.response);
            alert(errorJson.Message);
            updatePermissionState(businessMemberId, permission, !value);
        });
    }

    return (
        <React.Fragment>
            <Typography variant="h4">
                Members
            </Typography>
            {members &&
                <InfiniteScroll
                    dataLength={members?.length}
                    hasMore={!disableMembersInfiniteScroll}
                    next={getMembersPage}
                    loader={<div>Loading...</div>}>
                    <MembersTable changePermission={changePermission} members={members} />
                </InfiniteScroll>}
        </React.Fragment>
    );
}