import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"
import { Copyright } from "../../components/Copyright/Copyright"
import Client, { LoginResource } from "grow.client"
import { history } from '../../functions/history';
import { initalise } from '../../functions/initalise';
import { Link } from "react-router-dom"
import { TwoFactorAuthenticationModal } from "../../components/TwoFactorAuthenticationModal/TwoFactorAuthenticationModal";
import './LoginPage.scss';

export const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [twoFactorAuthenticationCode, setTwoFactorAuthenticationCode] = useState("");

    const [showTwoFactorAuthenticationModal, setShowTwoFactorAuthenticationModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage("");

        const client = new Client();

        const loginResource: LoginResource = {
            emailAddress: email,
            password,
            twoFactorAuthenticationCode
        };

        client.login(loginResource).then((authToken) => {
            if (authToken.twoFactorAuthenticationRequired) {
                setShowTwoFactorAuthenticationModal(true);
                setIsSubmitting(false);
                return;
            }

            initalise().finally(() => {
                history.push("/home");
                setIsSubmitting(false);
            });
        }).catch((res) => {
            const errorMessage = JSON.parse(res.response).Message;
            setErrorMessage(errorMessage);
            setIsSubmitting(false);
        });
    }

    return (
        <>
            <Container id="login-page" component="main" maxWidth="sm">
                <div className="paper">
                    <Avatar className="avatar">
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography variant="h3">
                        Login
                    </Typography>
                    <form className="form" onSubmit={onSubmit}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            onChange={e => setEmail(e.target.value)} />
                        <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disableElevation={true}
                            className="submit lg-button">
                            {!isSubmitting ? "Login" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                        </Button>
                        <Typography variant="body1" color="error" align="center">
                            {errorMessage}
                        </Typography>
                        <div className="forgotten-password">
                            <Link to="/forgotten-password">
                                Forgot password?
                            </Link>
                        </div>
                        <br />
                        <div style={{ textAlign: "center" }}>
                            {"No account? Sign up "}
                            <Link to="/sign-up">
                                here
                            </Link>
                            {"."}
                        </div>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
            <TwoFactorAuthenticationModal
                errorMessage={errorMessage}
                isOpen={showTwoFactorAuthenticationModal}
                isSubmitting={isSubmitting}
                verifyFunc={onSubmit}
                setShowModal={setShowTwoFactorAuthenticationModal}
                setTwoFactorAuthenticationCode={setTwoFactorAuthenticationCode}
                twoFactorAuthenticationCode={twoFactorAuthenticationCode}
            />
        </>
    )
}