import { DevotionalElement, EDevotionalElementType, EListElementType } from 'grow.client';
import { SettingsModal } from './ElementSettings/SettingsModal';
import { BibleElement } from './Elements/BibleElement/BibleElement';
import { ImageElement } from './Elements/ImageElement/ImageElement';
import { HeaderElement } from './Elements/HeaderElement/HeaderElement';
import { ListElement } from './Elements/ListElement/ListElement';
import { MusicTrackPanel } from './Elements/MusicTrackPanel/MusicTrackPanel';
import { ParagraphElement } from './Elements/ParagraphElement/ParagraphElement';
import { TitleElement } from './Elements/TitleElement/TitleElement';
import { YouTubeFrame } from './Elements/YouTubeFrame/YouTubeFrame';
import { DailyContentCanvasElementPlaceholder } from './DailyContentCanvasElementPlaceholder';
import { Draggable } from 'react-beautiful-dnd';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAppDispatch } from '../../../store/store';
import { addDevotionalDayElement, deleteDevotionalElement, setSelectedDailyContentElementId } from '../../../store/slices/devotionalBuilderSlice/devotionalBuilderHistorySlice';
import { dailyContentElements } from '../../../global/dailyContentElements';
import { createNewDailyContentCanvasElement } from '../../../functions/dailyContentElement';
import './DailyContentCanvasElement.scss';
import { useState } from 'react';
import { BibleSettings } from './ElementSettings/BibleSettings/BibleSettings';
import { GifSettings } from './ElementSettings/GifSettings/GifSettings';
import { HeaderSettings } from './ElementSettings/HeaderSettings/HeaderSettings';
import { ImageSettings } from './ElementSettings/ImageSettings/ImageSettings';
import { ListSettings } from './ElementSettings/ListSettings/ListSettings';
import { MusicTrackSettings } from './ElementSettings/MusicTrackSettings/MusicTrackSettings';
import { ParagraphSettings } from './ElementSettings/ParagraphSettings/ParagraphSettings';
import { TitleSettings } from './ElementSettings/TitleSettings/TitleSettings';
import { YouTubeSettings } from './ElementSettings/YouTubeSettings/YouTubeSettings';
import React from 'react';

interface Props {
    devotionalElement: DevotionalElement
    index: number
    selected: boolean
}

export const DailyContentCanvasElement: React.FC<Props> = React.memo(({ devotionalElement, index, selected }) => {
    const [showSettings, setShowSettings] = useState(false);
    const dispatch = useAppDispatch();

    const onSelect = () => {
        const selection = selected ? null : devotionalElement.id;
        dispatch(setSelectedDailyContentElementId(selection));
    }

    const onDoubleClick = () => {
        dispatch(setSelectedDailyContentElementId(devotionalElement.id));
        setShowSettings(true);
    }

    const deleteElement = () => {
        dispatch(deleteDevotionalElement(devotionalElement.id));
    }

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const type = e.dataTransfer.getData("type");
        if (type !== "DailyContentToolboxItem") return;

        const rect = e.currentTarget.getBoundingClientRect();
        const height = e.currentTarget.clientHeight;
        const y = e.clientY - rect.top;  //y position within the element.

        const toolboxItem = e.dataTransfer.getData("toolboxItem");

        const newElementIndex = height / 2 > y ? index : index + 1;
        const newDevotionalElement = createNewDailyContentCanvasElement(toolboxItem as EDevotionalElementType, newElementIndex);

        dispatch(addDevotionalDayElement({ index: newElementIndex, devotionalElement: newDevotionalElement }))
    }

    const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }

    let Component: JSX.Element | null = null;
    let SettingsComponent: JSX.Element | null = null;
    const element = dailyContentElements.find(e => e.type === devotionalElement.elementType);

    if (devotionalElement.elementType === EDevotionalElementType.Bible) {
        const bible = devotionalElement.bibleElement;
        if (bible?.passage && bible.book && bible.chapter > 0 && bible.firstVerse > 0)
            Component = <BibleElement devotionalElement={devotionalElement} />
        SettingsComponent = <BibleSettings devotionalElement={devotionalElement} />
    } else if (devotionalElement.elementType === EDevotionalElementType.Gif) {
        if (devotionalElement.gifElement?.url)
            Component = <ImageElement src={devotionalElement.gifElement.url} />
        SettingsComponent = <GifSettings devotionalElement={devotionalElement} closeModal={() => setShowSettings(false)} />
    } else if (devotionalElement.elementType === EDevotionalElementType.Header) {
        if (devotionalElement.headerElement?.type)
            Component = <HeaderElement type={devotionalElement.headerElement.type} />
        SettingsComponent = <HeaderSettings devotionalElement={devotionalElement} />
    } else if (devotionalElement.elementType === EDevotionalElementType.Image) {
        if (devotionalElement.imageElement?.url)
            Component = <ImageElement src={devotionalElement.imageElement.url} />
        SettingsComponent = <ImageSettings devotionalElement={devotionalElement} closeModal={() => setShowSettings(false)} />
    } else if (devotionalElement.elementType === EDevotionalElementType.List) {
        if (devotionalElement.listElement?.text && devotionalElement.listElement?.type)
            Component = <ListElement text={devotionalElement.listElement.text} type={devotionalElement.listElement.type} />
        SettingsComponent = <ListSettings devotionalElement={devotionalElement} />
    } else if (devotionalElement.elementType === EDevotionalElementType.Music) {
        if (devotionalElement.musicElement)
            Component = <MusicTrackPanel musicTrack={devotionalElement.musicElement} />
        SettingsComponent = <MusicTrackSettings devotionalElement={devotionalElement} closeModal={() => setShowSettings(false)} />
    } else if (devotionalElement.elementType === EDevotionalElementType.Paragraph) {
        if (devotionalElement.paragraphElement?.text)
            Component = <ParagraphElement text={devotionalElement.paragraphElement.text} />
        SettingsComponent = <ParagraphSettings devotionalElement={devotionalElement} />
    } else if (devotionalElement.elementType === EDevotionalElementType.Title) {
        if (devotionalElement.titleElement?.text)
            Component = <TitleElement text={devotionalElement.titleElement.text} />
        SettingsComponent = <TitleSettings devotionalElement={devotionalElement} />
    } else if (devotionalElement.elementType === EDevotionalElementType.YouTube) {
        if (devotionalElement.youTubeElement?.youTubeId)
            Component = <YouTubeFrame videoId={devotionalElement.youTubeElement.youTubeId} />
        SettingsComponent = <YouTubeSettings devotionalElement={devotionalElement} />
    }

    if (!Component && element) {
        Component = <DailyContentCanvasElementPlaceholder element={element} openSettings={() => setShowSettings(true)} />
    }

    return (

        <Draggable
            key={devotionalElement.id}
            draggableId={devotionalElement.id.toString()}
            index={index}
            disableInteractiveElementBlocking={false}
        >
            {(provided, snapshot) => (
                <div
                    className="devotional-element"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        ...provided.draggableProps.style,
                    }}
                    onClick={onSelect}
                    onDoubleClick={onDoubleClick}
                    onDrop={onDrop}
                    onDragOver={allowDrop}
                    key={devotionalElement.id}
                >
                    <div className={`element ${selected ? "selected" : ""}`}>
                        <div
                            className="devotional-element-buttons"
                            onClick={e => e.stopPropagation()}>
                            <IconButton onClick={() => setShowSettings(true)}>
                                <SettingsIcon />
                            </IconButton>
                            <IconButton onClick={deleteElement}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        {Component}
                        <SettingsModal
                            show={showSettings}
                            fullHeight={element?.settingsFullHeight ?? false}
                            size={element?.settingsSize ?? "sm"}
                            title={`${element?.name} Settings`}
                            handleClose={() => setShowSettings(false)}
                        >
                            {SettingsComponent}
                        </SettingsModal>
                    </div>
                </div>
            )}
        </Draggable>
    )
});