import React from "react";
import Avatar from '@mui/material/Avatar';
import { Profile, ProfileSmall } from "grow.client";

interface Props {
    profile: ProfileSmall | Profile | null
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const GrowAvatar: React.FC<Props> = ({ profile, onClick }) => {
    if (!profile) return null;

    return (
        <Avatar
            src={profile.image ?? "/assets/images/default-profile-image.jpg"}
            className="avatar"
            onClick={onClick}
        />
    );
}