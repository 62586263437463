import { TitleSettings } from '../components/DevotionalBuilder/DailyContentCanvas/ElementSettings/TitleSettings/TitleSettings';
import { EDevotionalElementType } from 'grow.client';
import { DailyContentElement } from './../models/DailyContent/DailyContentElement';
import TitleIcon from '@mui/icons-material/Title';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import BookIcon from '@mui/icons-material/Book';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ImageIcon from '@mui/icons-material/Image';
import { BibleElement } from '../components/DevotionalBuilder/DailyContentCanvas/Elements/BibleElement/BibleElement';
import { HeaderElement } from '../components/DevotionalBuilder/DailyContentCanvas/Elements/HeaderElement/HeaderElement';
import { ImageElement } from '../components/DevotionalBuilder/DailyContentCanvas/Elements/ImageElement/ImageElement';
import { ListElement } from '../components/DevotionalBuilder/DailyContentCanvas/Elements/ListElement/ListElement';
import { MusicTrackPanel } from '../components/DevotionalBuilder/DailyContentCanvas/Elements/MusicTrackPanel/MusicTrackPanel';
import { ParagraphElement } from './../components/DevotionalBuilder/DailyContentCanvas/Elements/ParagraphElement/ParagraphElement';
import { TitleElement } from '../components/DevotionalBuilder/DailyContentCanvas/Elements/TitleElement/TitleElement';
import { YouTubeFrame } from '../components/DevotionalBuilder/DailyContentCanvas/Elements/YouTubeFrame/YouTubeFrame';

export const dailyContentElements: Readonly<DailyContentElement[]> = [
    {
        name: "Title",
        type: EDevotionalElementType.Title,
        component: TitleElement,
        settingsComponent: TitleSettings,
        icon: TitleIcon,
        settingsFullHeight: false,
        settingsSize: "sm",
        group: "text"
    },
    {
        name: "Header",
        type: EDevotionalElementType.Header,
        component: HeaderElement,
        settingsComponent: HeaderElement,
        icon: ViewHeadlineIcon,
        settingsFullHeight: false,
        settingsSize: "sm",
        group: "text"
    },
    {
        name: "Paragraph",
        type: EDevotionalElementType.Paragraph,
        component: ParagraphElement,
        settingsComponent: ParagraphElement,
        icon: FormatAlignJustifyIcon,
        settingsFullHeight: false,
        settingsSize: "md",
        group: "text"
    },
    {
        name: "Bible",
        type: EDevotionalElementType.Bible,
        component: BibleElement,
        settingsComponent: BibleElement,
        icon: BookIcon,
        settingsFullHeight: true,
        settingsSize: "md",
        group: "text"
    },
    {
        name: "List",
        type: EDevotionalElementType.List,
        component: ListElement,
        settingsComponent: ListElement,
        icon: FormatListBulletedIcon,
        settingsFullHeight: false,
        settingsSize: "sm",
        group: "text"
    },
    {
        name: "Image",
        type: EDevotionalElementType.Image,
        component: ImageElement,
        settingsComponent: ImageElement,
        icon: CameraAltIcon,
        settingsFullHeight: true,
        settingsSize: "md",
        group: "multi-media"
    },
    {
        name: "Music",
        type: EDevotionalElementType.Music,
        component: MusicTrackPanel,
        settingsComponent: MusicTrackPanel,
        icon: HeadphonesIcon,
        settingsFullHeight: true,
        settingsSize: "sm",
        group: "multi-media"
    },
    {
        name: "YouTube",
        type: EDevotionalElementType.YouTube,
        component: YouTubeFrame,
        settingsComponent: YouTubeFrame,
        icon: YouTubeIcon,
        settingsFullHeight: true,
        settingsSize: "sm",
        group: "multi-media"
    },
    {
        name: "Gif",
        type: EDevotionalElementType.Gif,
        component: ImageElement,
        settingsComponent: ImageElement,
        icon: ImageIcon,
        settingsFullHeight: true,
        settingsSize: "md",
        group: "multi-media"
    }
];