import Button from '@mui/material/Button/Button';
import Paper from '@mui/material/Paper/Paper';
import { DailyContentElement } from '../../../models/DailyContent/DailyContentElement';
import './DailyContentCanvasElementPlaceholder.scss';

interface Props {
    element: DailyContentElement
    openSettings: () => void
}

export const DailyContentCanvasElementPlaceholder: React.FC<Props> = ({ element, openSettings }) => {
    const configure = (e: React.MouseEvent) => {
        e.stopPropagation();
        openSettings();
    }

    return (
        <div className="daily-content-canvas-element-placeholder">
            <Paper elevation={3}>
                <div className="header">
                    <element.icon />
                    <h3>{element.name}</h3>
                </div>
                <div className="button-wrapper">
                    <Button
                        onClick={configure}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation={true}
                        className="submit sm-button">
                        Configure
                    </Button>
                </div>
            </Paper>
        </div>
    )
}