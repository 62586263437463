import Client, { ImageSearch, GifSearch } from "grow.client";
import { ImageSearchGrid } from "./ImageSearchGrid";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

interface Props {
    columnCount: 2 | 3
    scrollableTargetId: string
    selectImage: (photo: ImageSearch | GifSearch) => void
}

export const PhotoSearchGrid: React.FC<Props> = ({ columnCount, scrollableTargetId, selectImage }) => {
    const getPhotos = (query: string | null | undefined, page: number | undefined) => {
        const client = new Client();
        return client.getImages(query, page);
    }

    const onClick = (photo: ImageSearch | GifSearch) => {
        selectImage(photo);
        const client = new Client();
        client.triggerImageDownload(photo.id);
    }

    return (
        <ImageSearchGrid
            columnCount={columnCount}
            getImages={getPhotos}
            scrollableTargetId={scrollableTargetId}
            selectImage={onClick}
            title={(
                <Typography>
                    Photos provided by <Link underline={"none"} href="https://unsplash.com" target="_blank">Unsplash</Link>
                </Typography>
            )}
        />
    )
}