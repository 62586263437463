import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessMember, BusinessMemberProfile, ProfileSmall } from 'grow.client';
import { RootState } from '../../store';

export interface sliceState {
    businessProfiles: BusinessMember[],
    userProfile: ProfileSmall | null
}

const initialState: sliceState = {
    businessProfiles: [],
    userProfile: null
};

const userSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        setBusinessProfiles: (state, action: PayloadAction<BusinessMember[]>) => {
            state.businessProfiles = action.payload;
        },
        setUserProfile: (state, action: PayloadAction<ProfileSmall>) => {
            state.userProfile = action.payload;
        },
        logOutUser: (state) => {
            state.businessProfiles = [];
            state.userProfile = null;
        }
    }
});

// Selects
export const selectProfileByUsername = (username: string) => {
    return (state: RootState) => {
        if (state.user.userProfile?.username === username)
            return state.user.userProfile;
        else 
            return state.user.businessProfiles.find(b => b.businessProfile?.username === username)?.businessProfile ?? null;
    }
}

export const selectBusinessMemberByUsername = (username: string) => {
    return (state: RootState) => {
        if (state.user.userProfile?.username === username)
            return null;
        else 
            return state.user.businessProfiles.find(b => b.businessProfile?.username === username) ?? null;
    }
}

export const selectDevotionalCreatorProfiles = (state: RootState) => {
    let profiles : (BusinessMemberProfile|ProfileSmall)[] = []
    profiles = state.user.businessProfiles.filter((b) => b.admin || b.devotionalCreator || b.devotionalCreatorWithReview)
        .map((b) => b.businessProfile!);

    if (state.user.userProfile !== null)
        profiles.push(state.user.userProfile)

    return profiles;
}

// Actions
export const { setBusinessProfiles, setUserProfile, logOutUser } = userSlice.actions;

export default userSlice.reducer;