import { PublishResult } from './../../../models/PublishResult';
import { DevotionalBuilderDevotional } from '../../../models/DevotionalBuilderDevotional';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Font, BibleTranslation, BibleFrame } from 'grow.client';

export interface sliceState {
    bibleFrame: BibleFrame | null
    bibleTranslations: BibleTranslation[] | null
    fonts: Font[] | null
    fontsLoaded: boolean
    isPublishing: boolean
    isSaving: boolean
    lastSavedDevotional: DevotionalBuilderDevotional | null
    publishResult: PublishResult | null
    showPublishMessage: boolean
}

const initialState: sliceState = {
    bibleFrame: null,
    bibleTranslations: null,
    fonts: null,
    fontsLoaded: false,
    isPublishing: false,
    isSaving: false,
    lastSavedDevotional: null,
    publishResult: null,
    showPublishMessage: false
};

const devotionalBuilderSlice = createSlice({
    name: 'devotionalBuilder',
    initialState,
    reducers: {
        resetState: (state) => {
            state.isPublishing = false;
            state.isSaving = false;
            state.lastSavedDevotional = null;
            state.publishResult = null;
            state.showPublishMessage = false;
        },
        setBibleFrame: (state, action: PayloadAction<BibleFrame>) => {
            state.bibleFrame = action.payload;
        },
        setBibleTranslations: (state, action: PayloadAction<BibleTranslation[]>) => {
            state.bibleTranslations = action.payload;
        },
        setFonts: (state, action: PayloadAction<Font[]>) => {
            state.fonts = action.payload;
        },
        setFontsLoaded: (state, action: PayloadAction<boolean>) => {
            state.fontsLoaded = action.payload;
        },
        setIsPublishing: (state, action: PayloadAction<boolean>) => {
            state.isPublishing = action.payload;
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setLastSavedDevotional: (state, action: PayloadAction<DevotionalBuilderDevotional>) => {
            state.lastSavedDevotional = action.payload;
        },
        setPublishResult: (state, action: PayloadAction<PublishResult>) => {
            state.publishResult = action.payload;
        },
        setShowPublishMessage: (state, action: PayloadAction<boolean>) => {
            state.showPublishMessage = action.payload;
        }
    }
});

// Actions
export const {
    resetState,
    setBibleFrame,
    setBibleTranslations,
    setFonts,
    setFontsLoaded,
    setIsPublishing,
    setIsSaving,
    setLastSavedDevotional,
    setPublishResult,
    setShowPublishMessage
} = devotionalBuilderSlice.actions;

export default devotionalBuilderSlice.reducer;